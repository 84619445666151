<template>
    <div>
        <v-card-title>
            <div v-if="!seqNumber">Добавяне на запис в диспансерен регистър</div>
            <div v-else>Преглед/редактиране на запис в диспансерен регистър</div>
        </v-card-title>
        <PatientSearch v-if="!seqNumber" />
        <v-card v-if="!register.dispensaryRuleId">
            <DispensaryData :value="register" />
        </v-card>
        <v-card>
            <v-card-title>Данни за регистрация</v-card-title>
            <v-card-text>
                <v-form ref="documentForm" v-model="isValid">
                    <v-row v-if="register.dispensaryRuleId">
                        <v-col cols="6" md="4"
                            ><text-field v-model="register.icdCode" required label="Основна диагноза" disabled
                        /></v-col>
                        <v-col cols="6" md="4">
                            <text-field v-model="register.icdCodeAdd" label="Допълнителна диагноза" disabled />
                        </v-col>
                        <v-col cols="6" md="3">
                            <text-field v-model="register.ageBracket" label="Възрастова група" disabled />
                        </v-col>
                        <v-col cols="auto">
                            <btn
                                v-if="register.dispensaryRuleId"
                                icon
                                action="Edit"
                                @click="register.dispensaryRuleId = 0"
                            >
                                Редактиране на диагнозата
                            </btn>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" md="4">
                            <date-picker v-model="register.registerDate" label="Дата на регистрация" required />
                        </v-col>
                        <v-col cols="12" md="4">
                            <text-field
                                v-model="register.dossierNumber"
                                type="number"
                                label="Номер на досие"
                                :disabled="seqNumber > 0"
                            />
                        </v-col>
                        <v-col cols="12" md="4">
                            <text-field v-model="register.ambulatorySheetNumber" label="Номер на АЛ" type="number" />
                        </v-col>
                    </v-row>

                    <v-textarea v-model="register.notes" dense label="Бележки" />

                    <v-row>
                        <v-col cols="12" md="4">
                            <date-picker v-model="register.deregisterDate" label="Дата на отписване" />
                        </v-col>
                        <v-col cols="12" md="4">
                            <dropdown
                                v-model="register.dispensaryDeregisterReasonId"
                                :items="deregisterReasons"
                                label="Причина за отписване"
                                clearable
                            ></dropdown>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <btn action="Back" @click="goBack()" />
                <btn v-if="$allowWrite" action="Save" @click="updateDispensaryRegister" />
                <v-spacer />
                <btn v-if="$allowWrite" action="Delete" @click="deleteDispensaryRegister" />
            </v-card-actions>
        </v-card>
        <NecessaryActivities
            :fund-lab-tests="register.dispensaryLabTests"
            :consultations="register.dispensaryConsultations"
            :suggested-future-exams="register.suggestedFutureExams"
        />
    </div>
</template>
<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import DispensaryData from '@/component/Register/DispensaryData.vue';
    import { RuleFilterDto } from '@/model/Exam/RuleFilterDto';
    import { DispensaryDeregisterReasonDto } from '@/model/Nomenclature/DispensaryDeregisterReasonDto';
    import { DispensaryRegisterDto } from '@/model/Register/Dispensary/DispensaryRegisterDto';
    import { DispensaryRuleDto } from '@/model/Register/Dispensary/DispensaryRuleDto';
    import { PatientRegisterKey } from '@/model/Register/PatientRegisterKey';
    import { dispensaryDeregisterReasonService } from '@/service/Nomenclature/DispensaryDeregisterReasonService';
    import { dispensaryRegisterService } from '@/service/Register/DispensaryRegisterService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import NecessaryActivities from '@/view/Register/NecessaryActivities.vue';
    import PatientSearch from '@/view/Register/PatientSearch.vue';

    @Component({
        components: { PatientSearch, NecessaryActivities, DispensaryData }
    })
    export default class DispensaryRegisterEdit extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        // При редактиране се подават пациент и seqNumber, без служител.
        @Prop()
        private patientId!: number | null;

        @Prop()
        private seqNumber!: number | null;

        // При добавяне се подава само служител, без пациент и seqNumber.
        @Prop()
        private doctorEmployeeSeqNumber!: number | null;

        private isValid: boolean = false;
        private selectedDispensaryOrdinanceText: string = '';
        private register: DispensaryRegisterDto = new DispensaryRegisterDto();
        private deregisterReasons: DispensaryDeregisterReasonDto[] = [];
        private dispensaryRuleFilter: RuleFilterDto = new RuleFilterDto();
        private dispensaryRules: DispensaryRuleDto[] = [];

        private async mounted() {
            this.$loading.show();
            try {
                await this.loadDeregisterReasons();

                if (this.patientId && this.seqNumber) {
                    await currentPatientCache.loadLastVersion(this.patientId);

                    const key = new PatientRegisterKey();
                    key.practiceId = this.practiceId;
                    key.patientId = this.patientId;
                    key.seqNumber = this.seqNumber;

                    this.register = await dispensaryRegisterService.getDispensaryRegister(key);
                } else if (this.doctorEmployeeSeqNumber) {
                    this.register.dossierNumber = await dispensaryRegisterService.getNextDispensaryNumber(
                        this.practiceId,
                        this.doctorEmployeeSeqNumber
                    );
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async loadDeregisterReasons() {
            const response = await dispensaryDeregisterReasonService.getDispensaryDeregisterReasons();
            this.deregisterReasons = response.data;
        }

        private async updateDispensaryRegister() {
            (this.$refs.documentForm as Vue & { validate: () => boolean }).validate();

            if (this.isValid) {
                if (!this.register.dossierNumber) {
                    this.register.dossierNumber = null;
                }
                if (this.doctorEmployeeSeqNumber) {
                    // Би следвало this.patientId и this.seqNumber да са празни.
                    this.register.key.practiceId = this.practiceId;
                    this.register.key.patientId = currentPatientCache.value.key.patientId;
                    this.register.doctorEmployeeSeqNumber = this.doctorEmployeeSeqNumber;
                    await dispensaryRegisterService.createDispensaryRegister(this.register);
                } else {
                    await dispensaryRegisterService.updateDispensaryRegister(this.register);
                }
                this.goBack();
            } else {
                this.$notifier.showWarning(
                    this.$t('notifications.validationErrors').toString(),
                    this.$t('notifications.pleaseFixValidationErrors').toString()
                );
            }
        }

        private async deleteDispensaryRegister() {
            this.$loading.show();
            try {
                await dispensaryRegisterService.deleteDispensaryRegister(this.register.key);
            } finally {
                this.$loading.hide();
            }
            this.$router.push(this.backRoute);
        }

        private get backRoute() {
            // TODO: Да връща, ту към регистъра, ту към диспансеризациите на пациента.
            return `/Register/DispensaryRegister/${this.practiceId}/${
                this.doctorEmployeeSeqNumber ?? this.register.doctorEmployeeSeqNumber
            }`;
        }

        private goBack() {
            this.$router.back();
        }
    }
</script>

<style scoped>
    pre {
        overflow-x: auto;
        white-space: pre-wrap;
        white-space: -moz-pre-wrap;
        white-space: -pre-wrap;
        white-space: -o-pre-wrap;
        word-wrap: break-word;
    }
</style>
