import { Type } from 'class-transformer';

import { PatientVersionKey } from '@/model/Patient/PatientVersionKey';

export class VisitResultDto {
    public id: number = 0;

    @Type(() => PatientVersionKey)
    public patientVersionKey: PatientVersionKey = new PatientVersionKey();

    public startDateTime: Date = new Date();
    public createdBy: string = '';
    public ambulatorySheetNumber: number | null = null;
    public hasStagedEpicrisis: boolean = false;
    public hasFiles: boolean = false;
    public hasExternalVisit: boolean = false;
    public nrn: string = '';
    public patientIdentifier: string = '';
    public patientFullName: string = '';
    public icdCode: string = '';
    public referral3: string = '';
    public referral3a: string = '';
    public referral4: string = '';
    public labTestOrders: string = '';
    public referral7: string = '';
    public immunization: string = '';
    public prescription: string = '';
    public sickSheetCount: number = 0;
    public nhifVisitReasonCode: string = '';
    public fundCode: string = '';
    public statusCode: string | null = null;

    public get examNumberOrVisitType() {
        return this.ambulatorySheetNumber !== null
            ? this.ambulatorySheetNumber
            : `${this.sickSheetCount ? 'БЛ без АЛ' : ''}${this.hasStagedEpicrisis ? ' Етапна' : ''}${
                  this.hasFiles ? ' Прикачени' : ''
              }${this.hasExternalVisit ? ' Резултати' : ''}`;
    }

    public get sickSheetCountOrNull() {
        return this.sickSheetCount > 0 ? this.sickSheetCount : null;
    }
}
