export class NhisVaccineDto {
    public code: string = '';
    public vaccineGroup: number | null = null;
    public nhisCode: string = '';
    public ownerName: string = '';
    public name: string = '';
    public atc: string = '';
    public inn: string = '';
    public doseQuantity: number = 0;
    public boosterDoseQuantity: number = 0;
    public targetIcdCode: string = '';
}
