<template>
    <dropdown
        v-model="value"
        :items="items"
        item-text="title"
        return-object
        required
        :disabled="disabled"
        label="Дейността се отчита по договор"
    >
    </dropdown>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import { PracticeNhifContractDto } from '@/model/Practice/PracticeNhifContractDto';
    import { practiceNhifContractService } from '@/service/Practice/PracticeNhifContractService';

    @Component
    export default class PracticeNhifContractPicker extends Vue {
        @Prop({ required: true })
        practiceId!: number;

        private value: PracticeNhifContractDto = new PracticeNhifContractDto();
        private items: PracticeNhifContractDto[] = [];

        private async getItems(practiceId: number) {
            this.items = await practiceNhifContractService.getByPracticeId(practiceId);
            if (this.items.length === 1) {
                [this.value] = this.items;
            }
            if (this.items.length === 0) {
                this.$notifier.showError('Грешка', 'Няма въведени договори към НЗОК.');
            }
        }

        private get disabled() {
            return this.items.length === 1;
        }

        @Watch('value')
        onValueChange(newValue: PracticeNhifContractDto): void {
            this.$emit('input', newValue);
        }

        @Watch('practiceId', { immediate: true })
        async onPracticeIdChange(newVal: number): Promise<void> {
            if (newVal) {
                await this.getItems(newVal);
            }
        }
    }
</script>
