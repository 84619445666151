import { Route, RouteConfig } from 'vue-router';

// ВАЖНО: Имената на параметрите в следните route-ове са съществени.
// Всички route-ове трябва да започват с параметър :template, а имената на останалите параметри
// трябва да съвпадат с имената на параметрите в .trdp файловете и същите да се parse-ват в *PrintService класовете.
const printChildRoutes: RouteConfig[] = [
    // Повечето печатни образци имат единствен параметър - числово id на документа.
    { path: ':id' },
    // За изключенията по-долу се декларират отделни route-ове с различен префикс, за да се match-ват еднозначно.
    // Ако нямаха различен префикс, router.push('/Print/Temp.late/id1/id2') щеше да match-не приозволен route с 4 сегмента.
    { path: 'GpChoice/:practiceId/:patientId/:patientVersionSeqNumber' },
    { path: 'Prescription/:examId/:prescriptionId' },
    { path: 'IncomingReferral/:visitId' },
    { path: 'ReferralResult4/:examId/:nrn' },
    { path: 'ReferralResultConsultation/:examId/:nrn' },
    { path: 'PaymentOrder/:practiceId/:seqNumber' },
    { path: 'Invoice/:invoiceType/:id' },
    { path: 'DeclarationESign/:visitId/:id' },
    { path: 'CustomerInvoice/:customerId/:invoiceNumber' },
    { path: 'ExamSchedule/:practiceId/:doctorId/:specialtyCode/:startDate/:endDate' },
    { path: 'RhiImmunization/:practiceId/:doctorEmployeeSeqNumber/:seqNumber' }
];

export const printRoutes: RouteConfig = {
    path: '/Print/:template',

    props: (route: Route) => {
        // Указва пътя до .trdp файла и до *PrintService класа спрямо .dll-ката на печатния web проект. Подпапките са разделени с точка вместо с /.
        const { template } = route.params;

        // Всички параметри от route-а, без шаблона, се подават като параметри на reporting service-а.
        const parameters: { [key: string]: string } = {};
        Object.assign(parameters, route.params);
        delete parameters.template;

        return { template, parameters };
    },

    // Печатната бланка се показва без второ вертикално меню, т.е. почти на цял екран.
    component: () => import('@/view/Print/Print.vue'),

    children: printChildRoutes
};
