<template>
    <v-card>
        <v-card-title>Необходими и извършени диспансерни дейности</v-card-title>
        <v-card-text>
            <v-row no-gutters>
                <dropdown
                    v-model="selectedDispensaryRule"
                    :items="patientDispensaryRules"
                    label="Избор на водещо МКБ"
                    return-object
                    class="col-6"
                ></dropdown
            ></v-row>
            <v-card-actions>
                <btn action="Save" :disabled="!selectedDispensaryRule || saveIsDisabled" @click="save"></btn>
            </v-card-actions>
            <v-card v-if="selectedDispensaryRule" class="mb-3">
                <v-card-title>Извършени дейности</v-card-title>
                <v-data-table
                    v-model="patientDispensaryData.selectedPerformedActivities"
                    :items="performedActivities"
                    :headers="performedHeaders"
                    :footer-props="{ itemsPerPageText: 'Редове на страница', itemsPerPageAllText: 'Всички' }"
                    show-select
                    :item-key="`code`"
                    item-selectable="isSelectable"
                ></v-data-table>
            </v-card>
            <v-card v-if="selectedDispensaryRule">
                <v-card-title>Необходими дейности</v-card-title>
                <v-data-table
                    v-model="patientDispensaryData.selectedRequiredActivities"
                    :items="requiredActivities"
                    :headers="requiredHeaders"
                    :footer-props="{ itemsPerPageText: 'Редове на страница', itemsPerPageAllText: 'Всички' }"
                    show-select
                    :item-key="`code`"
                    item-selectable="isSelectable"
                ></v-data-table>
            </v-card>
        </v-card-text>
    </v-card>
</template>
<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { PatientDiagnosisCheckCommand } from '@/model/Exam/PatientDiagnosisCheckCommand';
    import { PatientDispensaryDataDto } from '@/model/Patient/Dispensary/PatientDispensaryDataDto';
    import { PatientKey } from '@/model/Patient/PatientKey';
    import { PatientSpecialActivityDto } from '@/model/Patient/PatientSpecialActivityDto';
    import { DispensaryRuleDto } from '@/model/Register/Dispensary/DispensaryRuleDto';
    import { examDiagnosisService } from '@/service/Exam/ExamDiagnosisService';
    import { patientDispensaryVisitService } from '@/service/Patient/PatientDispensaryVisitService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { userContextCache } from '@/store/User/UserContextCache';

    @Component
    export default class PatientDispensaryVisit extends Vue {
        @Prop({ required: true })
        private patientId!: number;

        private patientDispensaryRules: DispensaryRuleDto[] = [];
        private selectedDispensaryRule: DispensaryRuleDto | null = null;
        private performedActivities: PatientSpecialActivityDto[] = [];
        private requiredActivities: PatientSpecialActivityDto[] = [];
        private patientDispensaryData: PatientDispensaryDataDto = new PatientDispensaryDataDto();
        private saveIsDisabled: boolean = false;
        private isLimitationsChecked: boolean = false;

        private get performedHeaders(): IDataTableHeader[] {
            return [
                {
                    text: 'Тип',
                    value: 'activityType'
                },
                {
                    text: 'Код/Специалист',
                    value: 'activityName'
                },
                {
                    text: 'Име на изследване',
                    value: 'labTestName'
                },
                {
                    text: 'Дата на последно извършване',
                    value: 'lastPerformedActivityDate'
                },
                {
                    text: 'Описание',
                    value: 'activityDescription'
                }
            ];
        }

        private get requiredHeaders(): IDataTableHeader[] {
            return [
                {
                    text: 'Тип',
                    value: 'activityType'
                },
                {
                    text: 'Код/Специалист',
                    value: 'activityName'
                },
                {
                    text: 'Име на изследване',
                    value: 'labTestName'
                },
                {
                    text: 'Извършени',
                    value: 'totalPerformedActivities'
                },
                {
                    text: 'Необходими',
                    value: 'totalActivities'
                },
                {
                    text: 'Описание',
                    value: 'activityDescription'
                }
            ];
        }

        private get currentPatient() {
            return currentPatientCache.value;
        }

        private async mounted() {
            this.patientDispensaryRules = await patientDispensaryVisitService.getPatientDispensaryRules(
                this.patientId,
                this.currentPatient.key.practiceId,
                userContextCache.currentDoctorEmployeeSeqNumber ?? 0
            );
        }

        private async save() {
            try {
                this.saveIsDisabled = true;
                this.$loading.show();
                this.patientDispensaryData.selectedDispensaryRule = this.selectedDispensaryRule;
                this.patientDispensaryData.deputyDoctorId = userContextCache.current?.deputyDoctorId ?? null;
                this.patientDispensaryData.deputyDoctorIsHired = userContextCache.current?.deputyDoctorIsHired ?? null;
                const response = await patientDispensaryVisitService.saveDispensaryVisit(this.patientDispensaryData);
                if (response) {
                    this.$router.push(`/Exam/AmbulatorySheet/Edit/${response}`);
                } else {
                    this.$notifier.showError('', 'Неуспешно създаване на амбулаторен лист.');
                }
            } finally {
                this.$loading.hide();
                this.saveIsDisabled = false;
            }
        }

        private async checkDispensaryLimit() {
            if (this.selectedDispensaryRule?.icdCode) {
                const command = new PatientDiagnosisCheckCommand();
                const patientKey = new PatientKey();
                patientKey.patientId = this.patientId;
                patientKey.practiceId = this.currentPatient.key.practiceId;
                command.patientKey = patientKey;
                command.icdCode = this.selectedDispensaryRule?.icdCode;
                command.examDate = new Date();
                const response = (await examDiagnosisService.checkForDispensaryLimitations(command)).data;
                if (response) {
                    this.$notifier.showWarning('Внимание', response);
                }
            }
        }

        @Watch('selectedDispensaryRule')
        private async onSelectedDispansaryRuleChanged() {
            this.performedActivities = [];
            this.requiredActivities = [];
            this.patientDispensaryData = new PatientDispensaryDataDto();
            if (this.selectedDispensaryRule) {
                const result = await patientDispensaryVisitService.getDispensaryActivities(
                    this.selectedDispensaryRule.dispensaryRegisterKey
                );
                this.patientDispensaryData = result;

                for (const activity of result.activities) {
                    if (activity.isPerformed) {
                        activity.isSelectable = activity.activityType === 'Изследване';
                        this.performedActivities.push(activity);
                    } else {
                        activity.isSelectable = true;
                        this.requiredActivities.push(activity);
                    }
                }

                if (!this.isLimitationsChecked) {
                    this.checkDispensaryLimit();
                    this.isLimitationsChecked = true;
                }
            }
        }
    }
</script>
