<template>
    <data-table
        title="Регистър Детско здравеопазване"
        :headers="headers"
        :items="registers"
        show-search-box
        :item-class="itemRowBackground"
    >
        <template #actions>
            <btn action="New" :to="`/Register/ChildrenHealthRegister/Create/${doctorEmployeeId}`">
                Добавяне на запис
            </btn>
            <btn action="Export" @click="exportExcel()">Експорт</btn>
        </template>
        <template #rowActions="{ item }">
            <btn icon action="Edit" :to="`/Register/ChildrenHealthRegister/Edit/${item.id}`" />
            <btn icon action="Delete" @click="deleteRegister(item)" />
        </template>
    </data-table>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { ChildrenHealthRegisterDto } from '@/model/Register/ChildrenHealth/ChildrenHealthRegisterDto';
    import { childrenHealthRegisterService } from '@/service/Register/ChildrenHealthRegisterService';
    import { arrayUtil } from '@/util/ArrayUtil';
    import { formatters } from '@/util/Formatters';

    @Component
    export default class ChildrenHealthRegister extends Vue {
        @Prop({ required: true })
        private doctorEmployeeId!: number;

        private registers: ChildrenHealthRegisterDto[] = [];

        private headers: IDataTableHeader[] = [
            { text: 'Пациент - Идентификатор', value: 'patientIdentifier' },
            { text: 'Пациент - Имена', value: 'patientName' },
            {
                text: 'Дата на регистрация',
                value: 'registerDate',
                filterable: false,
                formatter: formatters.date
            },
            { text: 'МКБ', value: 'icdCode' },
            { text: 'Допълнително МКБ', value: 'icdCodeAdd' },
            {
                text: 'Дата на отписване',
                value: 'deregisterDate',
                filterable: false,
                formatter: formatters.date
            },
            { text: '', value: 'actionButtons', sortable: false }
        ];

        private async load() {
            const response = await childrenHealthRegisterService.getAll(this.doctorEmployeeId);
            this.registers = response.data;
        }

        private itemRowBackground(item: ChildrenHealthRegisterDto) {
            if (item.deregisterDate !== null) {
                return 'style-deregistered';
            }
            return 'style-main';
        }

        private deleteRegister(itemToDelete: ChildrenHealthRegisterDto): void {
            childrenHealthRegisterService.delete(itemToDelete.id);
            arrayUtil.removeFirst(this.registers, itemToDelete);
        }

        private async exportExcel() {
            this.$loading.show();
            try {
                await childrenHealthRegisterService.exportToExcel(this.doctorEmployeeId);
            } finally {
                this.$loading.hide();
            }
        }

        private mounted() {
            return this.load();
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../../asset/Css/Register.css';
    /*По някаква причина долният импорт на по-новия формат css не работи. Трябва да търсим причината.*/
    /*@import '../../asset/Css/Register.scss'; */
</style>
