<template>
    <div>
        <v-card-title>
            <div v-if="register.id < 1">Добавяне на запис в регистър рискови групи</div>
            <div v-else>Преглед/редактиране на запис в регистър рискови групи</div>
        </v-card-title>
        <PatientSearch v-if="register.id < 1" />
        <v-card v-if="register.id < 1 || changeDiagnosis">
            <v-card-title>Данни за регистрация</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="3">
                        <text-field v-model="ruleFilter.Icd" label="МКБ" />
                    </v-col>
                    <v-col cols="12" md="7">
                        <text-field v-model="ruleFilter.IcdName" label="Диагноза" />
                    </v-col>
                    <v-col cols="12" md="2">
                        <btn action="Search" @click="search" />
                    </v-col>
                </v-row>
                <div v-if="riskGroupRules.length > 0">
                    <v-row>
                        <v-col>
                            <h3>Правила за регистрация</h3>
                            <data-table :headers="headers" :items="riskGroupRules" class="elevation-1">
                                <template #rowActions="{ item }">
                                    <btn icon action="Select" @click="selectRiskGroupRule(item.id)" />
                                    <btn icon action="Regulation" @click="openDialog(item.id)" />
                                </template>
                            </data-table>
                        </v-col>
                    </v-row>
                </div>
            </v-card-text>
        </v-card>
        <v-card>
            <v-card-title>Данни за регистрация</v-card-title>
            <v-card-text>
                <v-form ref="documentForm" v-model="isValid">
                    <v-row>
                        <v-col md="6">
                            <text-field v-model="register.riskGroupRule.icdCode" label="Основна диагноза" disabled />
                        </v-col>
                        <v-col md="6">
                            <text-field
                                v-model.number="register.ambulatorySheetNumber"
                                label="Номер на АЛ"
                                type="number"
                            />
                        </v-col>
                    </v-row>
                </v-form>

                <v-row>
                    <v-col cols="12" md="6">
                        <date-picker v-model="register.startDate" label="Дата на регистрация" required />
                    </v-col>
                    <v-col v-if="register.id > 0 && !changeDiagnosis" cols="12" md="4">
                        <btn action="Edit" @click="changeRule()">Редактиране на диагноза</btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12"><v-textarea v-model="register.description" dense label="Бележки" /></v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4">
                        <date-picker v-model="register.endDate" label="Дата на отписване" />
                    </v-col>
                    <v-col cols="12" md="4">
                        <dropdown
                            v-model="register.riskGroupDeregisterReason.id"
                            :items="deregisterReasons"
                            label="Причина за отписване"
                            clearable
                        ></dropdown>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <btn action="Back" :to="backRoute" />
                <v-spacer />
                <btn action="Save" @click="updateRiskGroupRegister" />
            </v-card-actions>
        </v-card>
        <v-dialog v-model="dialogVisible" transition="dialog-bottom-transition" max-width="900">
            <v-card>
                <v-card-title>
                    Описание и дейности
                    <v-spacer />
                    <btn icon action="Close" @click="dialogVisible = false" />
                </v-card-title>
                <v-card-text>
                    <div class="text-h6 pa-08">
                        {{ selectedOrdinanceText }}
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { RuleFilterDto } from '@/model/Exam/RuleFilterDto';
    import { RiskGroupDeregisterReasonDto } from '@/model/Nomenclature/RiskGroupDeregisterReasonDto';
    import { RiskGroupRegisterDto } from '@/model/Register/RiskGroup/RiskGroupRegisterDto';
    import { RiskGroupRuleDto } from '@/model/Register/RiskGroup/RiskGroupRuleDto';
    import { riskGroupDeregisterReasonService } from '@/service/Nomenclature/RiskGroupDeregisterReasonService';
    import { riskGroupRegisterService } from '@/service/Register/RiskGroupRegisterService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { userContextCache } from '@/store/User/UserContextCache';
    import PatientSearch from '@/view/Register/PatientSearch.vue';

    @Component({
        components: { PatientSearch }
    })
    export default class RiskGroupRegisterEdit extends Vue {
        @Prop()
        private id!: number;

        private isValid: boolean = false;
        private dialogVisible: boolean = false;
        private changeDiagnosis: boolean = false;
        private selectedOrdinanceText: string = '';
        private register: RiskGroupRegisterDto = new RiskGroupRegisterDto();
        private deregisterReasons: RiskGroupDeregisterReasonDto[] = [];
        private ruleFilter: RuleFilterDto = new RuleFilterDto();
        private riskGroupRules: RiskGroupRuleDto[] = [];
        private headers: IDataTableHeader[] = [
            { text: 'Диагноза', value: 'name' },
            { text: 'МКБ', value: 'icdCode' },
            { text: '', value: 'id' }
        ];

        private async load() {
            this.$loading.show();
            try {
                await this.loadDeregisterReasons();
                if (this.id) {
                    await this.getRiskGroupRegister(this.id);
                }
            } finally {
                this.$loading.hide();
            }
        }

        private openDialog(id: number) {
            this.dialogVisible = true;
            this.selectRiskGroupRule(id);
        }

        private selectRiskGroupRule(id: number) {
            const riskGroupRule = this.riskGroupRules.find((rule) => rule.id === id);
            if (riskGroupRule) {
                this.register.riskGroupRule = new RiskGroupRuleDto();
                this.register.riskGroupRule.id = id;
                this.register.riskGroupRule.icdCode = riskGroupRule.icdCode;
                this.selectedOrdinanceText = `Описание: ${riskGroupRule.description} Дейности: ${riskGroupRule.activities}`;
                this.riskGroupRules = [];
            }
        }

        private async loadDeregisterReasons() {
            const response = await riskGroupDeregisterReasonService.getRiskGroupDeregisterReasons();
            this.deregisterReasons = response.data;
        }

        private async getRiskGroupRegister(id: number) {
            const response = await riskGroupRegisterService.get(id);
            this.register = response.data;
            if (this.register.patientId) {
                currentPatientCache.loadLastVersion(this.register.patientId);
            }
        }

        private async updateRiskGroupRegister() {
            const doctorEmployeeId = userContextCache.currentDoctorEmployeeId;
            if (!currentPatientCache.isLoaded || !doctorEmployeeId) {
                return;
            }
            this.register.patientId = currentPatientCache.value.key.patientId;
            //this.register.doctorEmployeeId = doctorEmployeeId;
            (this.$refs.documentForm as Vue & { validate: () => boolean }).validate();

            if (this.isValid) {
                if (!this.id) {
                    await riskGroupRegisterService.create(this.register);
                } else {
                    this.register.id = this.id;
                    await riskGroupRegisterService.update(this.register);
                }
                this.$router.push(this.backRoute);
            } else {
                this.$notifier.showWarning(
                    this.$t('notifications.validationErrors').toString(),
                    this.$t('notifications.pleaseFixValidationErrors').toString()
                );
            }
        }

        private async search() {
            this.$loading.show();
            try {
                const response = await riskGroupRegisterService.searchRule(this.ruleFilter);
                this.riskGroupRules = response.data;
            } finally {
                this.$loading.hide();
            }
        }

        private changeRule() {
            this.changeDiagnosis = true;
        }

        private get backRoute() {
            return '/Register/RiskGroupRegister/';
        }

        private mounted() {
            return this.load();
        }
    }
</script>

<style scoped>
    pre {
        overflow-x: auto;
        white-space: pre-wrap;
        white-space: -moz-pre-wrap;
        white-space: -pre-wrap;
        white-space: -o-pre-wrap;
        word-wrap: break-word;
    }
</style>
