import { PatientInsuranceCheckRequestDto } from '@/model/Patient/InsuranceStatus/PatientInsuranceCheckRequestDto';

export class InsuranceCheckRequestDto {
    public practiceId: number = 0;
    public doctorEmployeeSeqNumber: number = 0;
    public startDate: Date | null = null;
    public endDate: Date | null = null;
    public allPatients: boolean = false;
    public skipCount: number = 0;
    public takeCount: number = 0;
    public patient: PatientInsuranceCheckRequestDto | null = null;
}
