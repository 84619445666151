import { NhifCommand } from '@/model/Nhif/NhifCommand';
import { NhifPrescriptionHistoryDto } from '@/model/Nhif/NhifPrescriptionHistoryDto';
import { notifierService } from '@/service/Infrastructure/NotifierService';
import { localServerDiagnosticService } from '@/service/LocalServer/LocalServerDiagnosticService';
import { localServerSignService } from '@/service/LocalServer/LocalServerSignService';
import { prescriptionBookService } from '@/service/Nhif/PrescriptionBookService';
import { currentPatientCache } from '@/store/CurrentPatientCache';
import { userContextCache } from '@/store/User/UserContextCache';

const createNhifCommand = function createNhifCommand(): NhifCommand {
    return new NhifCommand(
        currentPatientCache.value.key,
        userContextCache.current?.doctorUin ?? null,
        userContextCache.current?.deputyDoctorUin ?? null
    );
};

export const nhifPrescriptionBookService = {
    async prescriptionBookCheck(): Promise<string | null> {
        const requestDto = createNhifCommand();
        const responsePb = await prescriptionBookService.getXmlPrescriptionBookSearch(requestDto);
        console.log(responsePb.data);
        const isLocalServerActive = await localServerDiagnosticService.checkIsLocalServerActive();
        if (isLocalServerActive) {
            const signedPrescriptionBook = await localServerSignService.signXml(responsePb.data);
            if (!signedPrescriptionBook.isError) {
                const result = await prescriptionBookService.postPrescriptionBookSearch(
                    signedPrescriptionBook.contents ?? '',
                    currentPatientCache.value.key
                );
                console.log(result.response);
                if (result?.error?.length > 0) {
                    await notifierService.showWarning('Внимание', `${result.error}`);
                } else {
                    return result.prescriptionBookNumber;
                }
            }
        }
        return null;
    },

    async prescriptionHistory(): Promise<NhifPrescriptionHistoryDto[] | null> {
        const requestDto = createNhifCommand();
        const responsePb = await prescriptionBookService.getPrescriptionHistory(requestDto);
        console.log(responsePb.data);
        const isLocalServerActive = await localServerDiagnosticService.checkIsLocalServerActive();
        if (isLocalServerActive) {
            const signResponse = await localServerSignService.signXml(responsePb.data);
            if (!signResponse.isError && signResponse.contents) {
                const result = await prescriptionBookService.postPrescriptionHistory(signResponse.contents);
                console.log(result.response);
                if (result?.error?.length > 0) {
                    await notifierService.showWarning('Внимание', `${result.error}`);
                }
                if (result?.medications?.length > 0) {
                    return result.medications;
                }
            }
        }
        return null;
    }
};
