<template>
    <ExamLayout
        :can-save="!disableEdit"
        :can-delete="!disableEdit && document.key.prescriptionId > 0"
        :can-print="document.key.prescriptionId > 0"
        :title="`Рецептурна бланка - бл.МЗ-НЗОК №5${title}`"
        help-action="Form5"
        @updated="updateEventHandler"
        @deleted="deleteEventHandler"
        @printed="printEventHandler"
        @printed-without-preview="printWithoutPreviewHandler"
    >
        <nhis-prescription
            v-if="document.key.prescriptionId > 0 && document.nhisPrescription && isFormType5"
            v-model="document.nhisPrescription"
            :prescription-key="prescriptionKey"
            @input="copyObject"
            @reload-prescription="load"
        />
        <validation-alert
            :nhif-drugs="document.nhifDrugs"
            :exam-diagnosis="examDiagnosesForValidation"
            :protocol-type-code="document.protocolTypeCode"
            :issue-date="document.issueDate"
            :exam-id="document.key.examId"
        />
        <div v-if="!document.prescriptionBookNumber && (isFormType5B || isFormType5C)">
            <v-row>
                <alert type="info" prominent>
                    Внимание! За посочения пациент няма данни за въведена рецептурна книжка за ветерани!
                </alert>
            </v-row>
            <v-card-text class="text-h5 font-weight-meduim">
                Моля, попълнете данните за {{ isFormType5C ? 'Ветеран' : 'Военнопострадал/Военноинвалид' }}, като
                последвате <router-link :to="redirectToPrescriptionBook">този линк</router-link>.
            </v-card-text>
        </div>
        <v-card v-else class="mb-md-4" :disabled="disableEdit" :style="`background-color: ${backgroundColor}`">
            <main-title>{{ `Рецептурна бланка - бл.МЗ-НЗОК №5${title}` }}</main-title>

            <v-card-text>
                <v-row>
                    <v-col xs="6" md="3" cols="6"><text-field disabled label="Рецепта №(аптека)" /></v-col>
                    <v-col xs="6" md="3" cols="6"><text-field disabled label="Изпълнила аптека №" /></v-col>
                    <v-col xs="12" cols="12" md="3">
                        <dropdown
                            v-model="document.protocolTypeCode"
                            :items="prescriptionProtocolTypes"
                            item-text="code"
                            item-value="code"
                            required
                            label="Начин на предписание"
                            @input="clearAllDrugs"
                        />
                    </v-col>
                </v-row>

                <v-row v-if="document.protocolTypeCode !== 'Без протокол'">
                    <v-col xs="6" md="3" cols="6"
                        ><text-field v-model="document.protocolNumber" required label="Протокол №"
                    /></v-col>
                    <v-col xs="6" md="3" cols="6"
                        ><date-picker v-model="document.protocolDateTime" required label="Протокол Дата:"
                    /></v-col>
                    <v-col xs="6" md="3" cols="6">
                        <text-field v-model="document.protocolDays" required label="Валидност (дни):"
                    /></v-col>
                </v-row>
            </v-card-text>

            <v-card-text>
                <AmbulatorySheetHeaderInfoCard v-once :background-color="backgroundColor" />
            </v-card-text>
        </v-card>

        <prescription-info
            v-model="document"
            :exam-diagnoses="examDiagnoses"
            :disable-edit="disableEdit"
            :background-color="backgroundColor"
            @reload-diagnoses="getDiagnoses"
        />

        <v-card class="mb-4" :disabled="disableEdit" :style="`background-color: ${backgroundColor}`">
            <v-card-text v-if="isFormType5A">
                <v-row>
                    <v-col cols="12">
                        <v-tabs v-model="segmentCode" grow slider-color="accent" active-class="accent2 contrast--text">
                            <!-- Тук е описано защо се ползва href: https://github.com/vuetifyjs/vuetify/issues/10540#issuecomment-643731321 -->
                            <v-tab class="segment-title" :href="'#' + NhifPrescriptionSegmentCode.A">Отрязък A</v-tab>
                            <v-tab class="segment-title" :href="'#' + NhifPrescriptionSegmentCode.B"> Отрязък B </v-tab>
                            <v-tab class="segment-title" :href="'#' + NhifPrescriptionSegmentCode.C"> Отрязък C </v-tab>
                        </v-tabs>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions v-if="!disableEdit" class="py-0">
                <v-col v-if="showCopyBtn && isFormType5A" cols="2">
                    <btn action="Copy" :disabled="disableEdit" @click="copySegment(segmentCode)">
                        Копиране на отрязък {{ segmentCode === NhifPrescriptionSegmentCode.B ? 'А' : 'B' }}
                    </btn>
                </v-col>
                <v-spacer />
                <v-col cols="2">
                    <btn action="Delete" @click="clearAllDrugs">Изчистване</btn>
                </v-col>
            </v-card-actions>
            <v-card-text>
                <div v-if="isFormType5A">
                    <v-tabs-items v-model="segmentCode">
                        <v-tab-item
                            :value="NhifPrescriptionSegmentCode.A"
                            :style="`background-color: ${backgroundColor}`"
                        >
                            <v-row v-for="(nhifDrug, index) in document.nhifDrugs" :key="index">
                                <v-col cols="12" class="d-flex align-center">
                                    <drug-component
                                        v-model="document.nhifDrugs[index]"
                                        :exam-diagnosis="examDiagnosis(index)"
                                        :protocol-type-code="document.protocolTypeCode"
                                        :position="index + 1"
                                        :form-type="formType"
                                        :exam-diagnoses="examDiagnoses"
                                        required-drug
                                        :days-summit="calculateAccumulation(document.nhifDrugs[index])"
                                        :background-color="backgroundColor"
                                        :exam-id="document.key.examId"
                                        :advertisements="advertisements[examDiagnosis(index)?.icdCode ?? '']"
                                        @exam-diagnosis-selected="onExamDiagnosisSelected"
                                    />
                                    <btn
                                        v-if="!disableEdit && document.nhifDrugs.length > 1"
                                        icon
                                        action="Delete"
                                        @click="removeDrug(index)"
                                    />
                                </v-col>
                            </v-row>
                            <v-card-actions>
                                <btn v-if="!disableEdit" action="New" @click="addDrug">Добавяне на лекарство</btn>
                            </v-card-actions>
                        </v-tab-item>
                        <v-tab-item
                            :value="NhifPrescriptionSegmentCode.B"
                            :style="`background-color: ${backgroundColor}`"
                        >
                            <v-row v-for="(nhifDrug, index) in document.nhifDrugsB" :key="index">
                                <PrescriptionNhifAlert
                                    :selected-drug="document.nhifDrugsB[index]"
                                    :segment-a-drugs="document.nhifDrugs"
                                />
                                <v-col cols="12" class="d-flex align-center">
                                    <drug-component
                                        v-model="document.nhifDrugsB[index]"
                                        :exam-diagnosis="examDiagnosis(index)"
                                        :protocol-type-code="document.protocolTypeCode"
                                        :position="index + 1"
                                        :form-type="formType"
                                        :exam-diagnoses="examDiagnoses"
                                        required-drug
                                        :days-summit="calculateAccumulation(document.nhifDrugsB[index])"
                                        :background-color="backgroundColor"
                                        :exam-id="document.key.examId"
                                        :advertisements="advertisements[examDiagnosis(index)?.icdCode ?? '']"
                                        @exam-diagnosis-selected="onExamDiagnosisSelected"
                                    />
                                    <btn
                                        v-if="!disableEdit && document.nhifDrugsB.length > 1"
                                        icon
                                        action="Delete"
                                        @click="removeDrug(index)"
                                    />
                                </v-col>
                            </v-row>
                            <v-card-actions>
                                <btn v-if="!disableEdit" action="New" @click="addDrug">Добавяне на лекарство</btn>
                            </v-card-actions>
                        </v-tab-item>
                        <v-tab-item
                            :value="NhifPrescriptionSegmentCode.C"
                            :style="`background-color: ${backgroundColor}`"
                        >
                            <v-row v-for="(nhifDrug, index) in document.nhifDrugsC" :key="index">
                                <PrescriptionNhifAlert
                                    :selected-drug="document.nhifDrugsC[index]"
                                    :segment-a-drugs="document.nhifDrugs"
                                />
                                <v-col cols="12" class="d-flex align-center">
                                    <drug-component
                                        v-model="document.nhifDrugsC[index]"
                                        :exam-diagnosis="examDiagnosis(index)"
                                        :protocol-type-code="document.protocolTypeCode"
                                        :position="index + 1"
                                        :form-type="formType"
                                        :exam-diagnoses="examDiagnoses"
                                        required-drug
                                        :days-summit="calculateAccumulation(document.nhifDrugsC[index])"
                                        :background-color="backgroundColor"
                                        :exam-id="document.key.examId"
                                        :advertisements="advertisements[examDiagnosis(index)?.icdCode ?? '']"
                                        @exam-diagnosis-selected="onExamDiagnosisSelected"
                                    />
                                    <btn
                                        v-if="!disableEdit && document.nhifDrugsC.length > 1"
                                        icon
                                        action="Delete"
                                        @click="removeDrug(index)"
                                    />
                                </v-col>
                            </v-row>
                            <v-card-actions>
                                <btn v-if="!disableEdit" action="New" @click="addDrug">Добавяне на лекарство</btn>
                            </v-card-actions>
                        </v-tab-item>
                    </v-tabs-items>
                </div>
                <div v-else :style="`background-color: ${backgroundColor}`">
                    <v-row v-for="(nhifDrug, index) in document.nhifDrugs" :key="index">
                        <v-col cols="12" class="d-flex align-center">
                            <drug-component
                                v-model="document.nhifDrugs[index]"
                                :exam-diagnosis="examDiagnosis(index)"
                                :protocol-type-code="document.protocolTypeCode"
                                :position="index + 1"
                                :form-type="formType"
                                :exam-diagnoses="examDiagnoses"
                                required-drug
                                :days-summit="calculateAccumulation(document.nhifDrugs[index])"
                                :background-color="backgroundColor"
                                :exam-id="document.key.examId"
                                :advertisements="advertisements[examDiagnosis(index)?.icdCode ?? '']"
                                @exam-diagnosis-selected="onExamDiagnosisSelected"
                            />
                            <btn
                                v-if="!disableEdit && document.nhifDrugs.length > 1"
                                icon
                                action="Delete"
                                @click="removeDrug(index)"
                            />
                        </v-col>
                    </v-row>
                    <v-card-actions>
                        <btn v-if="!disableEdit && !disableAddDrugBtn" action="New" @click="addDrug"
                            >Добавяне на лекарство</btn
                        >
                    </v-card-actions>
                </div>
                <!--Всичко, което трябва да плати пациента-->
                <v-col md="3" offset-md="9">
                    <text-field v-model.number="totalCostPatient" label="Всичко:" disabled suffix="лв." />
                </v-col>
                <v-row>
                    <v-col md="2"><v-checkbox v-model="document.isPregnant" label="Бременна"></v-checkbox></v-col>
                    <v-col md="2"><v-checkbox v-model="document.isNursing" label="Кърмачка"></v-checkbox></v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <UnsavedChanges ref="leaveDialog" />
    </ExamLayout>
</template>

<script lang="ts">
    import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';

    import ExamLayout from '@/component/Exam/ExamLayout.vue';
    import PrescriptionInfo from '@/component/Exam/PrescriptionInfoV2.vue';
    import { AdvertisementTypeCode } from '@/enum/AdvertisementTypeCode';
    import { NhifPrescriptionSegmentCode } from '@/enum/Exam/NhifPrescriptionSegmentCode';
    import { PrescriptionFormTypeCode } from '@/enum/Exam/PrescriptionFormTypeCode';
    import { AdvertisementDto } from '@/model/Advertisement/AdvertisementDto';
    import { AdvertisementFilterDto } from '@/model/Advertisement/AdvertisementFilterDto';
    import { InfoByExamFilter } from '@/model/Exam/InfoByExamFilter';
    import { PrescriptionKey } from '@/model/Exam/Prescription/PrescriptionKey';
    import { prescriptionNhifConfig as config } from '@/model/Exam/Prescription/PrescriptionNhifConfig';
    import { PrescriptionNhifV2Dto } from '@/model/Exam/Prescription/PrescriptionNhifV2Dto';
    import {
        PrescriptionProtocolType,
        prescriptionProtocolTypes
    } from '@/model/Exam/Prescription/PrescriptionProtocolType';
    import { PrescriptionDiagnosisDto } from '@/model/Exam/PrescriptionDiagnosisDto';
    import { NhifDrugDto } from '@/model/Nomenclature/NhifDrugDto';
    import { advertisementService } from '@/service/AdvertisementService';
    import { prescriptionService } from '@/service/Exam/PrescriptionService';
    import { eventBus } from '@/service/Infrastructure/EventBus';
    import { printService } from '@/service/PrintService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import { userSettingsState } from '@/store/User/UserSettingsState';
    import { arrayUtil } from '@/util/ArrayUtil';
    import { objectUtil } from '@/util/ObjectUtil';
    import AmbulatorySheetHeaderInfoCard from '@/view/Exam/AmbulatorySheet/AmbulatorySheetHeaderInfoCard.vue';
    import PrescriptionNhifAlert from '@/view/Exam/PrescriptionNhif/PrescriptionNhifAlert.vue';
    import UnsavedChanges from '@/view/Exam/UnsavedChanges.vue';
    import NhisPrescription from '@/view/Nhis/Prescription/NhisPrescription.vue';

    import DrugComponent from './DrugComponent.vue';
    import ValidationAlert from './PrescriptionValidationAlertV2.vue';

    @Component({
        components: {
            PrescriptionNhifAlert,
            AmbulatorySheetHeaderInfoCard,
            UnsavedChanges,
            NhisPrescription,
            DrugComponent,
            ValidationAlert,
            ExamLayout,
            PrescriptionInfo
        },
        async beforeRouteLeave(to, from, next) {
            if (this?.$data?.leaveDialogRef) {
                await this.$data.leaveDialogRef.check(this.$data.initialValue, this.$data.document, next);
            } else {
                next();
            }
        }
    })
    export default class PrescriptionNhifV2 extends Vue {
        @Ref() readonly leaveDialog!: UnsavedChanges;

        @Prop({ required: true })
        private prescriptionKey!: PrescriptionKey;

        @Prop()
        private formType!: string;

        private document: PrescriptionNhifV2Dto = new PrescriptionNhifV2Dto();
        private initialValue: PrescriptionNhifV2Dto = new PrescriptionNhifV2Dto();

        private prescriptionProtocolTypes: PrescriptionProtocolType[] = prescriptionProtocolTypes;
        private leaveDialogRef: UnsavedChanges = new UnsavedChanges();
        private examDiagnoses: PrescriptionDiagnosisDto[] = [];
        private advertisements: { [key: string]: AdvertisementDto[] | null } = {};
        private segmentCodeField: NhifPrescriptionSegmentCode = NhifPrescriptionSegmentCode.A;
        // Така самият enum NhifPrescriptionSegmentCode става видим за template-а.
        private NhifPrescriptionSegmentCode: typeof NhifPrescriptionSegmentCode = NhifPrescriptionSegmentCode;

        private get disableAddDrugBtn() {
            const maxDrugCount = 3;
            return (
                (this.formType === PrescriptionFormTypeCode.Nhif5b ||
                    this.formType === PrescriptionFormTypeCode.Nhif5c) &&
                this.document.nhifDrugs.length >= maxDrugCount
            );
        }

        private get showCopyBtn() {
            if (this.segmentCode === NhifPrescriptionSegmentCode.B) {
                return !objectUtil.deepEqual(this.document?.nhifDrugs, this.document?.nhifDrugsB);
            } else if (this.segmentCode === NhifPrescriptionSegmentCode.C) {
                return !objectUtil.deepEqual(this.document?.nhifDrugsB, this.document?.nhifDrugsC);
            }
            return false;
        }

        private copySegment(segmentCodeArg: string) {
            if (segmentCodeArg === NhifPrescriptionSegmentCode.B) {
                this.document.nhifDrugsB = this.$lodash.cloneDeep(this.document.nhifDrugs);
                this.document.nhifDrugsB.forEach((drug) => (drug.segmentCode = segmentCodeArg));
            } else if (segmentCodeArg === NhifPrescriptionSegmentCode.C) {
                this.document.nhifDrugsC = this.$lodash.cloneDeep(this.document.nhifDrugsB);
                this.document.nhifDrugsC.forEach((drug) => (drug.segmentCode = segmentCodeArg));
            }
        }

        private get segmentCode() {
            return this.segmentCodeField;
        }

        private set segmentCode(newSegmentCode: NhifPrescriptionSegmentCode) {
            this.segmentCodeField = newSegmentCode;
        }

        private addDrug() {
            if (this.segmentCode === NhifPrescriptionSegmentCode.C) {
                this.document.nhifDrugsC.push(new NhifDrugDto());
            } else if (this.segmentCode === NhifPrescriptionSegmentCode.B) {
                this.document.nhifDrugsB.push(new NhifDrugDto());
            } else {
                this.document.nhifDrugs.push(new NhifDrugDto());
            }
        }

        private removeDrug(index: number) {
            if (this.segmentCode === NhifPrescriptionSegmentCode.C) {
                arrayUtil.removeAt(this.document.nhifDrugsC, index);
            } else if (this.segmentCode === NhifPrescriptionSegmentCode.B) {
                arrayUtil.removeAt(this.document.nhifDrugsB, index);
            } else {
                arrayUtil.removeAt(this.document.nhifDrugs, index);
            }
            this.updateDrugPosition();
        }

        private updateDrugPosition() {
            for (let index = 0; index < this.document.nhifDrugs.length; index++) {
                this.document.nhifDrugs[index].position = index + 1;
            }
        }

        private get isFormType5A() {
            return this.formType === PrescriptionFormTypeCode.Nhif5a;
        }

        private get isFormType5() {
            return this.formType === PrescriptionFormTypeCode.Nhif5 || this.isFormType5A;
        }

        private get isFormType5B() {
            return this.formType === PrescriptionFormTypeCode.Nhif5b;
        }

        private get isFormType5C() {
            return this.formType === PrescriptionFormTypeCode.Nhif5c;
        }

        private get redirectToPrescriptionBook(): string {
            if (!this.document.prescriptionBookNumber) {
                return `/Patient/PrescriptionBook/${currentPatientCache.value.key.patientId}`;
            }
            return 'Няма линк';
        }

        private get backgroundColor() {
            return !userSettingsState.userSettings.ui.hasReferralsBackground || !this.isFormType5
                ? 'transparent'
                : userSettingsState.userSettings.ui.isDarkTheme
                ? '#b08ba1'
                : '#ffe5f4';
        }

        private get title() {
            return this.formType === PrescriptionFormTypeCode.Nhif5
                ? ''
                : this.formType === PrescriptionFormTypeCode.Nhif5a
                ? 'A'
                : this.formType === PrescriptionFormTypeCode.Nhif5b
                ? 'Б'
                : 'В';
        }

        private get disableEdit() {
            return this.document?.nhisPrescription?.nrn?.length > 0;
        }

        private examDiagnosis(index: number) {
            return this.getPrescriptionDiagnosis(
                this.document.nhifDrugs[index].examDiagnosisId ?? 0,
                this.document.nhifDrugs[index].isExamDiagnosisAdd
            );
        }

        private onExamDiagnosisSelected(event: { examDiagnosisId: number | null; position: number; isAdd: boolean }) {
            if (event.examDiagnosisId) {
                this.document.nhifDrugs[event.position - 1].examDiagnosisId = event.examDiagnosisId;
                this.document.nhifDrugs[event.position - 1].isExamDiagnosisAdd = event.isAdd;
            }
        }

        private get examDiagnosesForValidation() {
            //Вземаме id-тата на диагнозите от всички лекарства
            const examDiagnosesIds = this.document.nhifDrugs.map((drug) => drug.examDiagnosisId);
            //Филтрираме само уникалните id-та
            const uniqueDiagnosesIds = [...new Set(examDiagnosesIds)];
            const prescriptionDiagnoses: PrescriptionDiagnosisDto[] = [];
            //Създаваме модели от тип PrescriptionDiagnosisDto, защото са необходими за компонент PrescriptionValidationAlert
            uniqueDiagnosesIds.forEach((id) => {
                const diagnosis = this.getPrescriptionDiagnosis(id, false);
                if (diagnosis) {
                    prescriptionDiagnoses.push(diagnosis);
                }
            });
            return prescriptionDiagnoses;
        }

        private get totalCostPatient(): number {
            let totalCost = Number(
                this.document.nhifDrugs
                    .map((drug) => drug.packageCount * drug.maxPatientPrice)
                    .reduce((partialSum, drugSum) => partialSum + drugSum, 0)
                    .toFixed(config.currencyPrecision)
            );

            if (this.isFormType5) {
                if (this.document.nhifDrugsB?.length > 0) {
                    totalCost += Number(
                        this.document.nhifDrugsB
                            .map((drug) => drug.packageCount * drug.maxPatientPrice)
                            .reduce((partialSum, drugSum) => partialSum + drugSum, 0)
                            .toFixed(config.currencyPrecision)
                    );
                }
                if (this.document.nhifDrugsC?.length > 0) {
                    totalCost += Number(
                        this.document.nhifDrugsC
                            .map((drug) => drug.packageCount * drug.maxPatientPrice)
                            .reduce((partialSum, drugSum) => partialSum + drugSum, 0)
                            .toFixed(config.currencyPrecision)
                    );
                }
            }
            return totalCost;
        }

        private clearAllDrugs() {
            if (this.segmentCode === NhifPrescriptionSegmentCode.C) {
                this.document.nhifDrugsC = [];
            } else if (this.segmentCode === NhifPrescriptionSegmentCode.B) {
                this.document.nhifDrugsB = [];
            } else {
                this.document.nhifDrugs = [];
            }
        }

        private calculateAccumulation(nhifDrug: NhifDrugDto): number {
            let result = 0;
            if (this.document.nhifDrugs.length > 0) {
                for (const nd of this.document.nhifDrugs) {
                    if (nd && (nd?.nhifId ?? 0) > 1 && nd.nhifId === nhifDrug.nhifId) {
                        result += nd?.therapyDuration ?? 0;
                    }
                }
                return result;
            }
            return 0;
        }

        private async updateEventHandler() {
            this.$loading.show();
            try {
                let { prescriptionId } = this.document.key;
                if (prescriptionId === 0) {
                    this.document.formTypeCode = this.formType;
                    const result = await prescriptionService.createV2(this.document);
                    prescriptionId = result.id;
                    const drugs = this.createDrugsTitle();
                    const title = `Бланка 5${this.title} - ${drugs}`;
                    eventBus.$emit('create-referral-event', prescriptionId, `PrescriptionV2${this.formType}`, title);
                    this.copyObject();
                    this.$router.push(
                        `/Exam/PrescriptionV2${this.formType}/Edit/${this.prescriptionKey.examId}/${prescriptionId}`
                    );
                } else {
                    await prescriptionService.updateV2(this.document);
                    const title = this.createDrugsTitle();
                    eventBus.$emit('update-referral-event', title);
                    this.copyObject();
                }
            } finally {
                this.$loading.hide();
            }
        }

        private createDrugsTitle() {
            return this.document.nhifDrugs.map((drug) => drug.nhifCode).join(', ');
        }

        private async deleteEventHandler() {
            this.$loading.show();
            try {
                await prescriptionService.delete(this.prescriptionKey);
                this.copyObject();
                this.$router.push(`/Exam/PrescriptionV2${this.formType}/Create/${this.prescriptionKey.examId}`);
                eventBus.$emit(
                    'delete-referral-event',
                    this.prescriptionKey.prescriptionId,
                    `PrescriptionV2${this.formType}`,
                    `Бланка 5${this.title} - №${this.document.prescriptionNumber}`
                );
            } finally {
                this.$loading.hide();
            }
        }

        private printEventHandler() {
            if (this.prescriptionKey.prescriptionId && this.document.nhisPrescription.nrn === null) {
                this.$router.push(
                    `/Print/Exam.Prescription.PrescriptionNhif/Prescription/${this.prescriptionKey.examId}/${this.prescriptionKey.prescriptionId}`
                );
            } else if (this.document && this.document.nhisPrescription.nrn !== '') {
                this.$router.push(
                    `/Print/Exam.Prescription.PrescriptionNhifTherapeuticCourse/Prescription/${this.prescriptionKey.examId}/${this.prescriptionKey.prescriptionId}`
                );
            }
        }

        private async printWithoutPreviewHandler() {
            this.$loading.show();
            try {
                let report = '';
                const parameters: { [key: string]: string } = {};
                parameters.examId = this.prescriptionKey.examId.toString();
                parameters.prescriptionId = this.prescriptionKey.prescriptionId.toString();
                if (this.prescriptionKey.prescriptionId && this.document.nhisPrescription.nrn === null) {
                    report = 'Exam.Prescription.PrescriptionNhif';
                } else if (this.document && this.document.nhisPrescription.nrn !== '') {
                    report = 'Exam.Prescription.PrescriptionNhifTherapeuticCourse';
                }

                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }

        private async load() {
            this.$loading.show();
            try {
                this.leaveDialogRef = this.leaveDialog;
                await currentVisitCache.load(this.prescriptionKey.examId);
                await this.getDiagnoses();
                if (!this.prescriptionKey.prescriptionId) {
                    this.document = await prescriptionService.getPrescriptionV2InfoByExamId(
                        new InfoByExamFilter(
                            this.prescriptionKey.examId,
                            currentVisitCache.value.startDateTime,
                            this.formType
                        )
                    );
                    this.document.nhifDrugs.push(new NhifDrugDto());
                } else {
                    this.document = await prescriptionService.getPrescriptionV2(this.prescriptionKey);
                }
            } finally {
                this.$loading.hide();
            }
        }

        private copyObject() {
            this.initialValue = this.$lodash.cloneDeep(this.document);
        }

        private async getDiagnoses() {
            const examCache = currentVisitCache.value?.exam;
            if (examCache) {
                for (const diagnosis of examCache.examDiagnoses) {
                    this.examDiagnoses.push(
                        this.createExamDiagnosis(diagnosis.id ?? 0, diagnosis.icdCode, diagnosis.icdName, false)
                    );
                    if (diagnosis.icdCodeAdd) {
                        this.examDiagnoses.push(
                            this.createExamDiagnosis(
                                diagnosis.id ?? 0,
                                diagnosis.icdCodeAdd,
                                diagnosis.icdNameAdd ?? '',
                                true
                            )
                        );
                    }
                }
            }
            await this.getAdvertisements();
        }

        private async getAdvertisements() {
            /* eslint-disable no-await-in-loop */
            for (const diagnosis of this.examDiagnoses) {
                this.advertisements[diagnosis.icdCode] = await advertisementService.getValidAdvertisements(
                    new AdvertisementFilterDto(AdvertisementTypeCode.Prescription, diagnosis.icdCode, null)
                );
            }
            /* eslint-enable no-await-in-loop */
        }

        @Watch('currentVisitCache')
        async onCurrentVisitCacheChange() {
            await this.getDiagnoses();
        }

        private createExamDiagnosis(id: number, icdCode: string, icdName: string, isAdd: boolean) {
            const diagnosis = new PrescriptionDiagnosisDto();
            diagnosis.id = id;
            diagnosis.icdCode = icdCode;
            diagnosis.icdName = icdName;
            diagnosis.isAdd = isAdd;
            return diagnosis;
        }

        public getPrescriptionDiagnosis(
            examDiagnosisId: number | null,
            isAdd: boolean
        ): PrescriptionDiagnosisDto | null {
            return (
                this.examDiagnoses.find(
                    (examDiagnosis) => examDiagnosis.id === examDiagnosisId && examDiagnosis.isAdd === isAdd
                ) ?? null
            );
        }

        private async mounted() {
            await this.load();
            this.copyObject();
        }
    }
</script>
