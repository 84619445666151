import { NecessaryConsultationDto } from '@/model/Register/NecessaryConsultationDto';
import { NecessaryNhifLabTestDto } from '@/model/Register/NecessaryNhifLabTestDto';

import { NecessaryImmunizationsDto } from './NecessaryImmunizationsDto';

export class ChildrenHealthRegisterDto {
    public id: number = 0;
    public doctorEmployeeId: number = 1;
    public patientId: number = 1;
    public childrenHealthRuleId: number = 0;
    public ambulatorySheetNumber: number | null = null;
    public registerDate: Date = new Date();
    public deregisterDate: Date | null = null;
    public childrenHealthDeregisterReasonId: number | null = null;
    public referralNumber: number | null = null;
    public referralDate: Date | null = null;
    public notes: string = '';
    public icdCode: string = '';
    public icdCodeAdd: string = '';
    public patientIdentifier: string = '';
    public patientName: string = '';
    public ordinanceText: string = '';
    public childrenHealthConsultations: NecessaryConsultationDto[] = [];
    public childrenHealthLabTests: NecessaryNhifLabTestDto[] = [];
    // TODO: Това поле никъде не се ползва.
    public childrenHealthImmunizations: NecessaryImmunizationsDto[] = [];
}
