import { AxiosResponse } from 'axios';
import { plainToInstance } from 'class-transformer';

import { AnthropometryViewDto } from '@/model/Exam/Anthropometry/AnthropometryViewDto';
import { AntrhropometryDto } from '@/model/Exam/Anthropometry/AntrhropometryDto';
import { UpdateExamConditionCommand } from '@/model/Exam/UpdateExamConditionCommand';
import { httpService } from '@/service/Infrastructure/HttpService';
import { currentVisitCache } from '@/store/CurrentVisitCache';

export const anthropometryService = {
    async create(data: AntrhropometryDto): Promise<number> {
        const response = await httpService.post<number>(`/Anthropometry/CreateAnthropometry`, data);
        return response.data;
    },

    updateAnthropometry(data: AntrhropometryDto) {
        return httpService.put(`/Anthropometry/UpdateAnthropometry`, data);
    },

    deleteAnthropometry(id: number): Promise<AxiosResponse> {
        return httpService.delete(`/Anthropometry/DeleteAnthropometry/${id}`);
    },

    async getAnthropometry(id: number): Promise<AntrhropometryDto> {
        const response = await httpService.get<AntrhropometryDto>(`/Anthropometry/GetAnthropometry/${id}`);
        return plainToInstance(AntrhropometryDto, response.data);
    },

    async getByExamId(examId: number): Promise<AnthropometryViewDto[]> {
        const response = await httpService.get<AntrhropometryDto[]>(`/Anthropometry/GetByExam/`, {
            params: { examId }
        });
        return plainToInstance(AnthropometryViewDto, response.data);
    },

    updateExamCondition(data: string): Promise<AxiosResponse> | null {
        if (currentVisitCache.isLoaded) {
            return httpService.post(
                '/Anthropometry/UpdateExamCondition/',
                new UpdateExamConditionCommand(currentVisitCache.value.id, data)
            );
        }
        return null;
    }
};
