import { AxiosResponse } from 'axios';

import { PensionStatusDto } from '@/model/Integration/Nssi/PensionStatusDto';
import { PensionStatusGroupCheckDto } from '@/model/Integration/Nssi/PensionStatusGroupCheckDto';
import { PatientVersionDto } from '@/model/Patient/PatientVersionDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const nssiService = {
    pensionStatusCheck(data: PatientVersionDto): Promise<AxiosResponse<PensionStatusDto>> {
        return httpService.post('/Nssi/PensionStatusCheck/', data);
    },

    async pensionStatusGroupCheck(data: PensionStatusGroupCheckDto): Promise<void> {
        await httpService.post('/Nssi/PensionStatusGroupCheck/', data);
    },

    async getPensionStatusCountToCheck(data: PensionStatusGroupCheckDto): Promise<number> {
        const response = await httpService.post<number>('/Nssi/GetPensionStatusCountToCheck/', data);
        return response.data;
    }
};
