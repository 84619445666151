<template>
    <div>
        <data-table
            :headers="headers"
            :items="regStandards"
            title="Протоколи за определяне на броя на назначаваните специализирани медицински дейности (СМД)
                    и стойността на назначаваните медико-диагностичните дейности (МДД) от изпълнител
                    на първична извънболнична медицинска помощ (ПИМП)"
            show-search-box
        >
            <template #actions>
                <btn action="New" :to="`/Practice/RegulatoryStandard/Create/${practiceId}`">Добавяне на запис</btn>
                <btn action="CheckList" :to="`/Practice/RegulatoryStandard/Reference/${practiceId}`">
                    Справка на текущите лимити за лекар
                </btn>
            </template>
            <template #rowActions="{ item }">
                <btn icon action="Edit" :to="`/Practice/RegulatoryStandard/Edit/${practiceId}/${item.seqNumber}`" />
            </template>
        </data-table>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { RegulatoryStandardDto } from '@/model/Practice/RegulatoryStandard/RegulatoryStandardDto';
    import { regulatoryStandardService } from '@/service/Practice/RegulatoryStandardService';
    import { formatters } from '@/util/Formatters';

    @Component
    export default class RegulatoryStandardList extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        private search: string = '';
        private regStandards: RegulatoryStandardDto[] = [];

        private headers: IDataTableHeader[] = [
            { text: 'Протокол №', value: 'number' },
            { text: 'Договор №', value: 'contractNumber' },
            { text: 'Дата от', value: 'startDate', formatter: formatters.date },
            { text: 'Дата до', value: 'endDate', formatter: formatters.date },
            { text: 'Тримесечие', value: 'yearQuarter' },
            {
                text: `Брой издавани „Медицинско направление за консултация или за провеждане на съвместно лечение”
                (бл. МЗ-НЗОК №3)`,
                value: 'sumCountReferral3Type124'
            },
            {
                text: `Брой издавани „Медицинско направление за консултация или за провеждане на съвместно лечение”
                (бл. МЗ-НЗОК №3)  за тип 7 `,
                value: 'sumCountReferral3Type7'
            },
            {
                text: 'Стойност на назначената с „Направление за медико-диагностична дейност” (бл. МЗ-НЗОК №4)',
                value: 'sumWorthReferral4AllTypes'
            },
            {
                text: 'Стойност на назначената с „Ядрено-магнитен резонанс” (бл. МЗ-НЗОК №4)',
                value: 'sumWorthReferral4Mri'
            },
            {
                text: `Брой издавани  „Медицинско направление за високоспециализирани дейности” (бл. МЗ-НЗОК №3А)
                за пакет "Анестезиология и интезивно лечение" и  диспансерно наблюдение`,
                value: 'sumCountReferral3aType4'
            }
        ];

        private async mounted() {
            this.regStandards = await regulatoryStandardService.getRegStandardsForPractice(this.practiceId);
        }
    }
</script>
