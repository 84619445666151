<template>
    <div>
        <v-card-title>
            <div v-if="register.id < 1">Добавяне на запис в регистър Бременности</div>
            <div v-else>Преглед/редактиране на запис в регистър Бременности</div>
        </v-card-title>
        <PatientSearch v-if="register.id < 1" />
        <v-card v-if="register.id < 1 || changeDiagnosis">
            <v-card-title>Данни за бременност</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="3">
                        <text-field v-model="ruleFilter.Icd" label="МКБ" />
                    </v-col>
                    <v-col cols="12" md="7">
                        <text-field v-model="ruleFilter.IcdName" label="Диагноза" />
                    </v-col>
                    <v-col cols="12" md="2">
                        <btn action="Search" @click="search" />
                    </v-col>
                </v-row>
                <div v-if="pregnancyRules.length > 0">
                    <v-row>
                        <v-col>
                            <h3>Правила за бременности</h3>
                            <data-table :headers="headers" :items="pregnancyRules" class="elevation-1">
                                <template #rowActions="{ item }">
                                    <btn icon action="Select" @click="selectPregnancyRule(item.id)" />
                                    <btn icon action="Regulation" @click="openDialog(item.id)" />
                                </template>
                            </data-table>
                        </v-col>
                    </v-row>
                </div>
            </v-card-text>
        </v-card>
        <v-card>
            <v-card-title>Данни за регистрация</v-card-title>
            <v-card-text>
                <v-form ref="documentForm" v-model="isValid">
                    <v-row>
                        <v-col cols="12" md="4">
                            <text-field v-model="register.icdCode" label="Основна диагноза" required disabled />
                        </v-col>
                        <v-col cols="12" md="4">
                            <text-field v-model="register.icdCodeAdd" label="Допълнителна диагноза" />
                        </v-col>
                        <v-col cols="12" md="4">
                            <text-field v-model="register.ambulatorySheetNumber" label="Номер на АЛ" type="number" />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" md="4">
                            <date-picker v-model="register.registerDate" label="Дата на регистрация" required />
                        </v-col>
                        <v-col cols="12" md="4">
                            <date-picker
                                v-model="register.lastRegularMenstruation"
                                label="ПРМ"
                                :required="register.icdCode !== 'Z39.2'"
                            />
                        </v-col>
                        <v-col cols="12" md="4">
                            <text-field
                                v-model="register.gestationalWeek"
                                label="Гестационна седмица"
                                type="number"
                                :required="register.icdCode !== 'Z39.2'"
                                :rules="[$validator.minValue(0), $validator.maxValue(43)]"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="4">
                            <date-picker v-model="register.probableBirthTerm" label="ВТР" />
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-select
                                v-model="register.pregnancyPeriodTypeId"
                                dense
                                :items="pregnancyPeriods"
                                item-text="name"
                                item-value="id"
                                label="Период на регистрация"
                                :rules="[$validator.required]"
                            />
                        </v-col>
                        <v-col cols="12" md="4">
                            <btn
                                v-if="register.id > 0 && !changeDiagnosis"
                                action="Edit"
                                @click="changeDispensaryRule()"
                            >
                                Редактиране на диагноза
                            </btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-textarea v-model="register.notes" dense label="Бележки" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="4">
                            <date-picker v-model="register.deregisterDate" label="Дата на отписване" />
                        </v-col>
                        <v-col cols="12" md="4">
                            <dropdown
                                v-model="register.pregnancyDeregisterReasonId"
                                :items="deregisterReasons"
                                label="Причина за отписване"
                                clearable
                            ></dropdown>
                        </v-col>
                        <v-col cols="12" md="4">
                            <date-picker
                                v-model="register.pregnancyEndDate"
                                label="Дата на завършване на бременността"
                            />
                        </v-col>
                        <v-col v-if="register.pregnancyDeregisterReasonId == 4" cols="12" md="4">
                            <dropdown
                                v-model="register.abortionTypeId"
                                :items="abortionTypes"
                                label="Вид аборт"
                                clearable
                            ></dropdown>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <btn action="Back" :to="backRoute" />
                <v-spacer />
                <btn v-if="id" action="Next" @click="openPregnancyCard">Карта за профилактика</btn>
                <btn v-if="id" action="Next" @click="openPregnancyReferral">Направление на бременна</btn>
                <btn v-if="$allowWrite" action="Save" @click="updatePregnancyRegister" />
            </v-card-actions>
        </v-card>
        <v-dialog v-model="dialogVisible" transition="dialog-bottom-transition" max-width="900">
            <v-card>
                <v-card-title>
                    Наредба за бременност
                    <v-spacer />
                    <btn icon action="Close" @click="dialogVisible = false" />
                </v-card-title>
                <v-card-text>
                    <pre class="text-h6 pt-8">{{ selectedPregnancyOrdinanceText }}</pre>
                </v-card-text>
            </v-card>
        </v-dialog>
        <NecessaryActivities
            :fund-lab-tests="register.pregnancyLabTests"
            :consultations="register.pregnancyConsultations"
        />
    </div>
</template>
<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import { SpecialtyCode } from '@/enum/Nomenclature/SpecialtyCode';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { RuleFilterDto } from '@/model/Exam/RuleFilterDto';
    import { AbortionTypeDto } from '@/model/Nomenclature/AbortionTypeDto';
    import { PregnancyDeregisterReasonDto } from '@/model/Nomenclature/PregnancyDeregisterReasonDto';
    import { PregnancyPeriodTypeDto } from '@/model/Nomenclature/PregnancyPeriodTypeDto';
    import { PregnancyRegisterDto } from '@/model/Register/Pregnancy/PregnancyRegisterDto';
    import { PregnancyRuleDto } from '@/model/Register/Pregnancy/PregnancyRuleDto';
    import { abortionTypeService } from '@/service/Nomenclature/AbortionTypeService';
    import { pregnancyDeregisterReasonService } from '@/service/Nomenclature/PregnancyDeregisterReasonService';
    import { pregnancyPeriodTypeService } from '@/service/Nomenclature/PregnancyPeriodTypeService';
    import { pregnancyCardService } from '@/service/Register/PregnancyCardService';
    import { pregnancyReferralService } from '@/service/Register/PregnancyReferralService';
    import { pregnancyRegisterService } from '@/service/Register/PregnancyRegisterService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { userContextCache } from '@/store/User/UserContextCache';
    import NecessaryActivities from '@/view/Register/NecessaryActivities.vue';
    import PatientSearch from '@/view/Register/PatientSearch.vue';

    @Component({
        components: { PatientSearch, NecessaryActivities }
    })
    export default class PregnancyRegisterEdit extends Vue {
        @Prop()
        private doctorEmployeeId!: number;

        @Prop()
        private id!: number;

        private isValid: boolean = false;
        private dialogVisible: boolean = false;
        private changeDiagnosis: boolean = false;
        private selectedPregnancyOrdinanceText: string = '';
        private register: PregnancyRegisterDto = new PregnancyRegisterDto();
        private deregisterReasons: PregnancyDeregisterReasonDto[] = [];
        private pregnancyPeriods: PregnancyPeriodTypeDto[] = [];
        private abortionTypes: AbortionTypeDto[] = [];
        private ruleFilter: RuleFilterDto = new RuleFilterDto();
        private pregnancyRules: PregnancyRuleDto[] = [];
        private headers: IDataTableHeader[] = [
            { text: 'Диагноза', value: 'name' },
            { text: 'МКБ', value: 'icdCode' },
            { text: 'Допълнително МКБ', value: 'icdCodeAdd' },
            { text: '', value: 'id' }
        ];

        private get isGynecology() {
            return userContextCache?.current?.specialtyCode === SpecialtyCode.Gynecology;
        }

        private async load() {
            this.$loading.show();
            try {
                await this.loadDeregisterReasons();
                await this.loadAbortionTypes();
                await this.loadPregnancyPeriodTypes();
                if (this.id) {
                    await this.getPregnancyRegister(this.id);
                }
            } finally {
                this.$loading.hide();
            }
        }

        @Watch('register.lastRegularMenstruation')
        private onLastRegularMenstruationChanged() {
            if (this.register.icdCode === 'Z39.2') {
                this.register.gestationalWeek = 0;
            } else {
                const daysInWeek: number = 7;
                const thousand: number = 1000;
                const sixty: number = 60;
                const hours: number = 24;
                const now: Date = new Date();
                const timeInMilisec: number = now.getTime() - this.register.lastRegularMenstruation.getTime();
                const daysBetweenDates: number = Math.ceil(timeInMilisec / (thousand * sixty * sixty * hours));
                const gestationalWeek = Math.floor(daysBetweenDates / daysInWeek + 1);
                const gestationalWeekLimit = 43;
                this.register.gestationalWeek =
                    gestationalWeek > gestationalWeekLimit ? gestationalWeekLimit : gestationalWeek;
                this.calculateProbableBirthTerm();
            }
        }

        private calculateProbableBirthTerm() {
            const thousand: number = 1000;
            const sixty: number = 60;
            const days: number = 280;
            const hours: number = 24;
            this.register.probableBirthTerm = new Date(
                this.register.lastRegularMenstruation.getTime() + days * (thousand * sixty * sixty * hours)
            );
        }

        private openDialog(id: number) {
            this.dialogVisible = true;
            this.selectPregnancyRule(id);
        }

        private selectPregnancyRule(id: number) {
            this.register.pregnancyRuleId = id;
            const pregnancyRule = this.pregnancyRules.find((rule) => rule.id === id);
            if (pregnancyRule) {
                this.register.icdCode = pregnancyRule.icdCode;
                this.register.icdCodeAdd = pregnancyRule.icdCodeAdd;
                this.selectedPregnancyOrdinanceText = pregnancyRule.ordinanceText;
                this.pregnancyRules = [];
            }
        }

        private async loadPregnancyPeriodTypes() {
            const response = await pregnancyPeriodTypeService.getPregnancyPeriodTypes();
            this.pregnancyPeriods = response.data;
        }

        private async loadDeregisterReasons() {
            const response = await pregnancyDeregisterReasonService.getPregnancyDeregisterReasons();
            this.deregisterReasons = response.data;
        }

        private async loadAbortionTypes() {
            const response = await abortionTypeService.getAbortionTypes();
            this.abortionTypes = response.data;
        }

        private async getPregnancyRegister(id: number) {
            const response = await pregnancyRegisterService.get(id);
            this.register = response.data;
            if (this.register.patientId) {
                currentPatientCache.loadLastVersion(this.register.patientId);
            }
        }

        private async openPregnancyCard() {
            const response = await pregnancyCardService.getPregnancyCardId(this.id);
            if (response?.data) {
                await this.$router.push(`/Register/PregnancyRegister/PregnancyCard/Edit/${response.data}`);
            } else {
                await this.$router.push(`/Register/PregnancyRegister/PregnancyCard/Create/${this.id}`);
            }
        }

        private async openPregnancyReferral() {
            const response = await pregnancyReferralService.getPregnancyReferralId(this.id);
            if (response?.data) {
                await this.$router.push(`/Register/PregnancyRegister/PregnancyReferral/Edit/${response.data}`);
            } else {
                await this.$router.push(`/Register/PregnancyRegister/PregnancyReferral/Create/${this.id}`);
            }
        }

        private async updatePregnancyRegister() {
            if (!currentPatientCache.isLoaded) {
                return;
            }
            this.register.patientId = currentPatientCache.value.key.patientId;
            this.register.doctorEmployeeId = this.doctorEmployeeId ?? userContextCache.currentDoctorEmployeeId;

            (this.$refs.documentForm as Vue & { validate: () => boolean }).validate();
            if (this.isValid) {
                if (!this.id) {
                    await pregnancyRegisterService.create(this.register);
                } else {
                    this.register.id = this.id;
                    await pregnancyRegisterService.update(this.register);
                }
                this.$router.push(this.backRoute);
            } else {
                this.$notifier.showWarning(
                    this.$t('notifications.validationErrors').toString(),
                    this.$t('notifications.pleaseFixValidationErrors').toString()
                );
            }
        }

        private async search() {
            this.$loading.show();
            try {
                const response = await pregnancyRegisterService.searchPregnancyRule(this.ruleFilter);
                this.pregnancyRules = response.data;
            } finally {
                this.$loading.hide();
            }
        }

        private changeDispensaryRule() {
            this.changeDiagnosis = true;
        }

        private get backRoute() {
            return `/Register/PregnancyRegister/${this.doctorEmployeeId ?? userContextCache.currentDoctorEmployeeId}`;
        }

        private mounted() {
            return this.load();
        }
    }
</script>

<style scoped>
    pre {
        overflow-x: auto;
        white-space: pre-wrap;
        white-space: -moz-pre-wrap;
        white-space: -pre-wrap;
        white-space: -o-pre-wrap;
        word-wrap: break-word;
    }
</style>
