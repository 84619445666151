<template>
    <div>
        <data-table
            :headers="headers"
            :items="items"
            :loading="$loading.isVisible"
            title="Подлежащи на имунизация"
            :item-class="itemRowBackground"
        >
            <template #actions>
                <v-row>
                    <v-col cols="12" lg="6">
                        <report-date-picker v-model="command" :disabled="$loading.isVisible" @input="getTableItems" />
                    </v-col>
                    <v-col cols="12" lg="6">
                        <!-- Не се използва <immunization-picker>, защото е ориентиран към добавяне на имунизации.
                    <immunization-picker v-model="command.immunizationId" @input="getTableItems" />-->
                        <dropdown
                            v-model="command.immunizationId"
                            :items="immunizations"
                            item-text="title"
                            clearable
                            label="Избор на имунизация"
                            @input="getTableItems"
                        ></dropdown>
                    </v-col>
                    <v-col cols="6" md="3">
                        <btn action="Export" block @click="exportExcel" />
                    </v-col>
                </v-row>
            </template>
        </data-table>
        <v-row>
            <v-col cols="auto">Легенда:</v-col>
            <v-col cols="auto"><label class="py-1 style-uninsured">Неосигурен</label></v-col>
        </v-row>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import ReportDatePicker from '@/component/Date/ReportDatePicker.vue';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { ImmunizationDto } from '@/model/Nomenclature/ImmunizationDto';
    import { ImmunizationReportCommand } from '@/model/Patient/Immunization/Report/ImmunizationReportCommand';
    import { SubjectToImmunizationDto } from '@/model/Query/Patient/SubjectToImmunization/SubjectToImmunizationDto';
    import { immunizationService } from '@/service/Nomenclature/ImmunizationService';
    import { immunizationReportService } from '@/service/Query/Patient/ImmunizationReportService';
    import { loadingState } from '@/store/LoadingState';
    import { formatters } from '@/util/Formatters';

    @Component({
        components: { ReportDatePicker }
    })
    export default class SubjectToImmunization extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        @Prop({ required: true })
        private doctorEmployeeSeqNumber!: number;

        private command: ImmunizationReportCommand = new ImmunizationReportCommand();
        private items: SubjectToImmunizationDto[] = [];
        private immunizations: ImmunizationDto[] = [];

        private headers: IDataTableHeader[] = [
            { text: 'Идентификатор', value: 'patientIdentifier' },
            { text: 'Име пациент', value: 'patientName' },
            { text: 'Телефон', value: 'patientPhone' },
            { text: 'Дата на раждане', value: 'patientBirthdate', formatter: formatters.date },
            { text: 'Код НЗОК', value: 'nhifCode' },
            { text: 'Код РЗИ', value: 'rhiCode' },
            { text: 'Имунизация', value: 'immunizationName' }
        ];

        private async getImmunizations() {
            const filterByNhis = false;
            this.immunizations = await immunizationService.getActiveImmunizations(filterByNhis);
        }

        private async getTableItems() {
            this.$loading.show();
            try {
                this.items = [];
                this.fillDoctorEmployee();
                const response = await immunizationReportService.getSubjectToImmunization(this.command);
                this.items = response ?? [];
            } finally {
                this.$loading.hide();
            }
        }

        private async exportExcel() {
            loadingState.show();
            try {
                this.fillDoctorEmployee();
                await immunizationReportService.exportSubjectToImmunization(this.command);
            } finally {
                loadingState.hide();
            }
        }

        private fillDoctorEmployee() {
            this.command.practiceId = this.practiceId;
            this.command.doctorEmployeeSeqNumber = this.doctorEmployeeSeqNumber;
        }

        private async mounted() {
            this.command.fillCurrentMonth();
            await this.getImmunizations();
            await this.getTableItems();
        }

        private itemRowBackground(patient: SubjectToImmunizationDto) {
            if (!patient?.patientIsInsured) {
                return 'style-uninsured';
            }
            return 'style-main';
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../../asset/Css/Register.css';
</style>
