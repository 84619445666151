export class EmployeeTitleDto {
    public employeeSeqNumber: number = 0;
    public isDeleted: boolean = false;
    public employeeDescription: string = '';
    public isPracticeOwner: number = 0;
    public userIsPending: boolean = false;

    // Останалите полета нямат смисъл ако служителят не е лекар.
    public doctorName: string = '';
    public doctorUin: string = '';
    public specialtyName: string = '';

    public get doctorNameOrEmployeeDescription() {
        return this.doctorName ?? this.employeeDescription;
    }

    // Този getter се използва за dropdown-и с лекари, където е по-прегледно УИН-ът да бъде на първо място.
    public get uinNameAndSpecialty() {
        const title = this.doctorUin
            ? `${this.doctorUin}, ${this.doctorName}, ${this.specialtyName}`
            : this.employeeDescription;
        return `${title}${this.isDeleted ? ` (${this.isDeletedText})` : ''}`;
    }

    // Този getter се използва за заглавия на екрани, където е по-прегледно името да бъде на първо място.
    public get employeeTitle() {
        const title = this.doctorUin
            ? `${this.doctorName}, УИН ${this.doctorUin}, ${this.specialtyName}`
            : this.employeeDescription;
        return `${title}${this.isDeleted ? ` (${this.isDeletedText})` : ''}`;
    }

    public get isDeletedText(): string {
        return this.isDeleted ? 'неактивен' : '';
    }

    public get rolesText(): string {
        return this.isPracticeOwner ? 'Админ' : '';
    }

    public get itemClass(): string {
        return this.isDeleted ? 'secondary2' : '';
    }
}
