<template>
    <ExamLayout
        :can-delete="seqNumber > 0"
        :can-save="true"
        :allow-write="true"
        title="Резултати или медицински документ"
        @updated="updateEventHandler"
        @deleted="deleteEventHandler"
    >
        <v-card>
            <v-card-text>
                <v-row>
                    <v-col cols="4">
                        <dropdown
                            v-model="externalVisit.documentType"
                            label="Вид документ"
                            :items="externalVisitDocumentTypes"
                            required
                            item-text="name"
                            item-value="code"
                        />
                    </v-col>
                    <v-col cols="4">
                        <text-field
                            v-model="externalVisit.documentNumber"
                            label="Номер на документ"
                            :rules="[$validator.maxLength(20)]"
                        ></text-field>
                    </v-col>
                    <v-col cols="4">
                        <date-picker v-model="externalVisit.date" label="Дата" required />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4">
                        <text-field
                            v-model="externalVisit.practiceName"
                            label="Име на лечебното заведение"
                            :rules="[$validator.maxLength(100)]"
                        ></text-field>
                    </v-col>
                    <v-col cols="6" md="4">
                        <text-field
                            v-model="externalVisit.doctorName"
                            label="Име на лекаря"
                            :rules="[$validator.maxLength(100)]"
                        />
                    </v-col>
                    <v-col cols="6" md="4">
                        <specialty-picker v-model="externalVisit.specialty" />
                    </v-col>
                </v-row>
                <h4>Водеща диагноза</h4>
                <v-row no-gutters>
                    <v-col cols="12" md="5">
                        <icd-picker v-model="externalVisit.mainIcdCode" />
                    </v-col>
                    <v-col cols="6" md="5">
                        <icd-picker v-model="externalVisit.mainIcdCodeAdd" label="Допълнително МКБ" />
                    </v-col>
                    <v-col cols="6" md="2">
                        <v-checkbox
                            v-model="externalVisit.isCoronary"
                            class="mt-0"
                            label="Коронарен инцидент"
                        ></v-checkbox>
                    </v-col>
                </v-row>
                <v-textarea
                    v-model="externalVisit.otherIcdCodes"
                    label="Придружаващи диагнози"
                    rows="2"
                    :rules="[$validator.maxLength(100)]"
                ></v-textarea>
                <v-row>
                    <v-col class="py-0">
                        <v-textarea v-model="externalVisit.symptoms" label="Анамнеза" rows="4"></v-textarea>
                    </v-col>
                    <v-col class="py-0">
                        <v-textarea v-model="externalVisit.condition" label="Обективно състояние" rows="4"></v-textarea>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="py-0">
                        <v-textarea v-model="externalVisit.tests" label="Изследвания" rows="4"></v-textarea>
                    </v-col>
                    <v-col class="py-0">
                        <v-textarea v-model="externalVisit.treatment" label="Терапия" rows="4"></v-textarea>
                    </v-col>
                </v-row>
                <v-textarea v-model="externalVisit.notes" label="Бележки" rows="3"></v-textarea>
            </v-card-text>

            <v-card-title class="pt-0">
                <v-row>
                    <v-col>Резултати от изследвания</v-col>
                    <v-col cols="auto"><btn action="New" @click="addLabTest"></btn></v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-row v-for="(labTest, index) in externalVisit.labTests" :key="index">
                    <v-col>
                        <ExternalVisitLabTest v-model="externalVisit.labTests[index]" />
                    </v-col>
                    <v-col cols="auto">
                        <btn action="Delete" icon @click="externalVisit.labTests.splice(index, 1)" />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </ExamLayout>
</template>
<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import ExamLayout from '@/component/Exam/ExamLayout.vue';
    import IcdPicker from '@/component/Picker/IcdPicker.vue';
    import SpecialtyPicker from '@/component/Picker/SpecialtyPicker.vue';
    import { ExternalVisitDto } from '@/model/Exam/ExternalVisit/ExternalVisitDto';
    import { ExternalVisitLabTestDto } from '@/model/Exam/ExternalVisit/ExternalVisitLabTestDto';
    import { ExternalDocumentTypeDto } from '@/model/Nomenclature/ExternalDocumentTypeDto';
    import { externalVisitService } from '@/service/Exam/ExternalVisitService';
    import { externalDocumentTypeService } from '@/service/Nomenclature/ExternalDocumentTypeService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import { userContextCache } from '@/store/User/UserContextCache';

    import ExternalVisitLabTest from './ExternalVisitLabTest.vue';

    @Component({ components: { IcdPicker, ExamLayout, ExternalVisitLabTest, SpecialtyPicker } })
    export default class ExternalVisit extends Vue {
        @Prop()
        private visitId!: number;

        @Prop()
        private practiceId!: number;

        @Prop()
        private patientId!: number;

        @Prop()
        private seqNumber!: number;

        private externalVisitDocumentTypes: ExternalDocumentTypeDto[] = [];
        private externalVisit: ExternalVisitDto = new ExternalVisitDto();

        private async mounted() {
            if (this.practiceId && this.patientId && this.seqNumber) {
                this.externalVisit = await externalVisitService.getExternalVisit(
                    this.practiceId,
                    this.patientId,
                    this.seqNumber
                );
            }
            this.externalVisitDocumentTypes = await externalDocumentTypeService.getExternalVisitDocumentTypes();
        }

        private addLabTest() {
            this.externalVisit.labTests.push(new ExternalVisitLabTestDto());
        }

        private async updateEventHandler() {
            this.$loading.show();
            try {
                if (this.externalVisit.seqNumber) {
                    await externalVisitService.updateExternalVisit(this.externalVisit);
                    this.$notifier.showSuccess('', `Успешен запис`);
                } else {
                    this.externalVisit.practiceId = userContextCache.currentPracticeId ?? 0;
                    if (this.patientId || this.visitId) {
                        if (this.patientId) {
                            this.externalVisit.patientId = this.patientId;
                        } else if (this.visitId) {
                            this.externalVisit.patientId = currentPatientCache.value.key.patientId;
                            this.externalVisit.visitId = this.visitId;
                        }
                        await externalVisitService.createExternalVisit(this.externalVisit);
                        this.$notifier.showSuccess('', 'Успешен запис');
                        this.$router.back();
                    } else {
                        this.createVisitWithExternalVisit();
                    }
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async createVisitWithExternalVisit() {
            const doctorEmployeeSeqnumber = userContextCache.currentDoctorEmployeeSeqNumber;
            if (doctorEmployeeSeqnumber) {
                this.externalVisit.patientId = currentPatientCache.value.key.patientId;
                const visitId = await externalVisitService.createVisitWithExternalVisit(
                    doctorEmployeeSeqnumber,
                    this.externalVisit
                );
                // Зареждането на новосъздаденото посещение подменя и link-овете в менюто с такива с visitId.
                await currentVisitCache.load(visitId);
                this.$notifier.showSuccess('', 'Успешен запис');
                this.$router.push(`/Exam/Results/${visitId}`);
            }
        }

        private async deleteEventHandler() {
            const visitIsDeleted = await externalVisitService.deleteExternalVisit(
                this.externalVisit.practiceId,
                this.externalVisit.patientId,
                this.seqNumber
            );
            this.$notifier.showSuccess('', 'Успешно изтрит запис');
            if (visitIsDeleted) {
                this.$router.push(`/Patient/VisitSummary/${this.externalVisit.patientId}`);
            } else {
                this.$router.back();
            }
        }
    }
</script>
