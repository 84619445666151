<template>
    <div>
        <data-table
            :headers="headers"
            :items="items"
            :loading="$loading.isVisible"
            title="Подлежащи на диспансерен преглед"
            :item-class="itemRowBackground"
        >
            <template #actions>
                <v-row>
                    <v-col cols="12">
                        <report-date-picker v-model="filter" :disabled="$loading.isVisible" @input="getTableItems" />
                    </v-col>
                    <v-col cols="6" md="3">
                        <btn action="Export" block @click="exportExcel" />
                    </v-col>
                </v-row>
            </template>
        </data-table>
        <v-row>
            <v-col cols="auto">Легенда:</v-col>
            <v-col cols="auto"><label class="py-1 style-uninsured">Неосигурен</label></v-col>
        </v-row>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import ReportDatePicker from '@/component/Date/ReportDatePicker.vue';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { SubjectToDispensaryExamDto } from '@/model/Query/Exam/SubjectToDispensaryExamDto';
    import { ReportIntervalFilterDto } from '@/model/Report/ReportIntervalFilterDto';
    import { subjectToDispensaryExamService } from '@/service/Query/Exam/SubjectToDispensaryExamService';
    import { loadingState } from '@/store/LoadingState';
    import { formatters } from '@/util/Formatters';

    @Component({
        components: { ReportDatePicker }
    })
    export default class SubjectToDispensaryExam extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        @Prop({ required: true })
        private doctorEmployeeSeqNumber!: number;

        private filter: ReportIntervalFilterDto = new ReportIntervalFilterDto();
        private items: SubjectToDispensaryExamDto[] = [];

        private headers: IDataTableHeader[] = [
            { text: 'Идентификатор', value: 'identifier' },
            { text: 'Име', value: 'name' },
            { text: 'Дата на раждане', value: 'birthdate', formatter: formatters.date },
            { text: 'Адрес', value: 'address' },
            { text: 'Телефон', value: 'mobilePhone' },
            { text: 'Мкб', value: 'icd' },
            { text: 'Направени прегледи брой', value: 'currentExamCount' },
            { text: 'Полагаеми прегледи брой', value: 'requiredExamCount' }
        ];

        private async getTableItems() {
            this.$loading.show();
            try {
                this.items = [];
                this.fillDoctorEmployee();
                const response = await subjectToDispensaryExamService.getSubjectToDispensaryExam(this.filter);
                this.items = response.data;
            } finally {
                this.$loading.hide();
            }
        }

        private async exportExcel() {
            loadingState.show();
            try {
                this.fillDoctorEmployee();
                await subjectToDispensaryExamService.exportSubjectToDispensaryExam(this.filter);
            } finally {
                loadingState.hide();
            }
        }

        private fillDoctorEmployee() {
            this.filter.practiceId = this.practiceId;
            this.filter.doctorEmployeeSeqNumber = this.doctorEmployeeSeqNumber;
        }

        private async mounted() {
            this.filter.fillCurrentYear();
            await this.getTableItems();
        }

        private itemRowBackground(patient: SubjectToDispensaryExamDto) {
            if (!patient?.patientIsInsured) {
                return 'style-uninsured';
            }
            return 'style-main';
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../../asset/Css/Register.css';
</style>
