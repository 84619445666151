<template>
    <exam-layout
        :can-delete="document.id > 0"
        :can-print="document.id > 0"
        title="Протокол за предписване на лекарства, заплащани от НЗОК/РЗОК"
        @updated="updateEventHandler"
        @deleted="deleteEventHandler"
        @printed="printEventHandler"
    >
        <nhif-protocol-info
            v-if="document.id > 0 && document.nhifProtocolInfo"
            v-model="document.nhifProtocolInfo"
            class="mb-2"
            :protocol-id="document.id"
            @input="onProtocolInfoChange"
        />
        <v-card v-if="previousProtocols.length > 0 && !document.id" class="mb-3">
            <v-card-text>
                <v-col cols="6">
                    <dropdown label="Копиране от протокол" :items="previousProtocols" @input="copyProtocol" />
                </v-col>
            </v-card-text>
        </v-card>
        <v-card>
            <main-title>Протокол за предписване на лекарства, заплащани от НЗОК/РЗОК</main-title>
            <v-card-text>
                <v-row class="my-2">
                    <v-col cols="12" md="6"><PatientInfo /></v-col>
                    <v-col cols="12" md="6"><DoctorInfo /></v-col>
                </v-row>
                <v-row class="my-2">
                    <v-col cols="12" md="3">
                        <text-field v-model.trim="document.number" type="number" required label="Номер:" />
                    </v-col>
                    <v-col xs="12" md="3" cols="12">
                        <date-picker v-model="document.issueDate" required label="Дата на издаване:" />
                    </v-col>
                    <v-col xs="12" md="3" cols="12">
                        <dropdown
                            v-model="document.groupCode"
                            class="required"
                            item-text="code"
                            item-value="code"
                            :items="protocolTypes"
                            required
                            label="Начин на предписание:"
                            @input="resetDrugs"
                    /></v-col>
                    <v-col xs="12" md="3" cols="12">
                        <dropdown
                            v-model="document.communicationCode"
                            class="required"
                            item-value="code"
                            :items="communications"
                            required
                            label="Начин на уведомяване:"
                        />
                    </v-col>
                    <v-col xs="12" md="3" cols="12">
                        <dropdown
                            v-model="document.nhifOfficeId"
                            class="required"
                            :items="nhifOffices"
                            required
                            label="Да се разглежда от:"
                        />
                    </v-col>
                    <v-col cols="12" md="3">
                        <text-field
                            v-model.trim="document.validityPeriod"
                            type="number"
                            required
                            label="За срок в дни:"
                        />
                    </v-col>
                    <v-col cols="12" md="3">
                        <text-field v-model.trim="document.decisionNumber" label="Номер на решение:" />
                    </v-col>
                    <v-col cols="12" md="3">
                        <text-field v-model.trim="document.previousProtocol" label="Номер на предходен протокол:" />
                    </v-col>
                </v-row>
                <v-row class="my-2">
                    <v-col cols="12">
                        <nhif-protocol-diagnosis-picker v-model="document.examDiagnosis" @input="onDiagnosisChange" />
                    </v-col>
                </v-row>
                <v-row class="my-2">
                    <v-col cols="12">
                        <dropdown
                            v-model="document.requirementId"
                            class="required"
                            :items="requirements"
                            required
                            label="Изискване на НЗОК:"
                        />
                    </v-col>
                </v-row>
                <v-row v-for="(drug, index) in document.drugs" :key="index" class="my-2">
                    <v-col cols="12">
                        <v-row class="align-baseline">
                            <nhif-protocol-drug-component
                                v-model="document.drugs[index]"
                                :exam-diagnosis="examDiagnosis"
                                :protocol-type-code="document.groupCode"
                                :position="index + 1"
                                :requirement-id="document.requirementId"
                                @update-nhif="updateNhif"
                            />
                            <btn v-if="document.drugs.length > 1" icon action="Delete" @click="removeDrug(index)" />
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <btn v-if="document.drugs.length < 3" action="Pill" @click="addDrug">Добавяне на лекарство</btn>
            </v-card-actions>
            <v-divider />
            <nhif-protocol-document v-model="document.documents" />
        </v-card>
        <UnsavedChanges ref="leaveDialog" />
    </exam-layout>
</template>

<script lang="ts">
    import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';

    import DatePicker from '@/component/Date/DatePicker.vue';
    import DoctorInfo from '@/component/Exam/DoctorInfo.vue';
    import ExamLayout from '@/component/Exam/ExamLayout.vue';
    import PatientInfo from '@/component/Exam/PatientInfo.vue';
    import { NhifProtocolDropdownDto } from '@/model/Exam/DrugProtocol/NhifProtocolDropdownDto';
    import { NhifProtocolDrugDto } from '@/model/Exam/DrugProtocol/NhifProtocolDrugDto';
    import { NhifProtocolDto } from '@/model/Exam/DrugProtocol/NhifProtocolDto';
    import { NhifProtocolType, nhifProtocolType } from '@/model/Exam/DrugProtocol/NhifProtocolType';
    import { ExamDiagnosisDto } from '@/model/Exam/ExamDiagnosisDto';
    import { PrescriptionDiagnosisDto } from '@/model/Exam/PrescriptionDiagnosisDto';
    import { NhifProtocolCommunicationDto } from '@/model/Nomenclature/NhifProtocolCommunicationDto';
    import { NhifProtocolRequirementDto } from '@/model/Nomenclature/NhifProtocolRequirementDto';
    import { RhifDto } from '@/model/Nomenclature/RhifDto';
    import { drugProtocolService } from '@/service/Exam/Protocol/DrugProtocolService';
    import { eventBus } from '@/service/Infrastructure/EventBus';
    import { nhifProtocolCommunicationService } from '@/service/Nomenclature/NhifProtocolCommunicationService';
    import { nhifProtocolRequirementService } from '@/service/Nomenclature/NhifProtocolRequirementService';
    import { rhifService } from '@/service/Nomenclature/RhifService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import { arrayUtil } from '@/util/ArrayUtil';
    import NhifProtocolDiagnosisPicker from '@/view/Exam/DrugProtocol/NhifProtocolDiagnosisPicker.vue';
    import NhifProtocolDocument from '@/view/Exam/DrugProtocol/NhifProtocolDocument.vue';
    import NhifProtocolDrugComponent from '@/view/Exam/DrugProtocol/NhifProtocolDrugComponent.vue';
    import NhifProtocolInfo from '@/view/Exam/DrugProtocol/NhifProtocolInfo.vue';
    import UnsavedChanges from '@/view/Exam/UnsavedChanges.vue';

    @Component({
        components: {
            NhifProtocolDrugComponent,
            NhifProtocolDiagnosisPicker,
            NhifProtocolInfo,
            NhifProtocolDocument,
            DatePicker,
            UnsavedChanges,
            ExamLayout,
            DoctorInfo,
            PatientInfo
        },
        async beforeRouteLeave(to, from, next) {
            await this.$data.leaveDialogRef.check(this.$data.initialValue, this.$data.document, next);
        }
    })
    export default class NhifProtocolEditor extends Vue {
        @Ref()
        readonly leaveDialog!: UnsavedChanges;

        @Prop()
        private examId!: number;

        @Prop()
        private documentId!: number;

        private document: NhifProtocolDto = new NhifProtocolDto();
        private initialValue: NhifProtocolDto = new NhifProtocolDto();
        private previousProtocols: NhifProtocolDropdownDto[] = [];
        private protocolTypes: NhifProtocolType[] = nhifProtocolType;
        private leaveDialogRef: UnsavedChanges = new UnsavedChanges();
        private requirements: NhifProtocolRequirementDto[] = [];
        private communications: NhifProtocolCommunicationDto[] = [];
        private nhifOffices: RhifDto[] = [];
        private examDiagnoses: PrescriptionDiagnosisDto[] = [];

        private get examDiagnosis() {
            return this.getPrescriptionDiagnosis(this.document?.examDiagnosis?.id ?? 0, false);
        }

        private onProtocolInfoChange() {
            this.$emit('input', this.document?.nhifProtocolInfo);
            this.copyObject();
        }

        public getPrescriptionDiagnosis(
            examDiagnosisId: number | null,
            isAdd: boolean
        ): PrescriptionDiagnosisDto | null {
            return (
                this.examDiagnoses.find(
                    (examDiagnosis) => examDiagnosis.id === examDiagnosisId && examDiagnosis.isAdd === isAdd
                ) ?? null
            );
        }

        private getDiagnoses() {
            const examCache = currentVisitCache.value?.exam;
            if (examCache) {
                for (const diagnosis of examCache.examDiagnoses) {
                    this.examDiagnoses.push(
                        this.createExamDiagnosis(diagnosis.id ?? 0, diagnosis.icdCode, diagnosis.icdName, false)
                    );
                }
            }
        }

        private updateNhif(event: { nhifDrug: NhifProtocolDrugDto; position: number }) {
            this.document.drugs[event.position - 1] = event.nhifDrug;
        }

        private createExamDiagnosis(id: number, icdCode: string, icdName: string, isAdd: boolean) {
            const diagnosis = new PrescriptionDiagnosisDto();
            diagnosis.id = id;
            diagnosis.icdCode = icdCode;
            diagnosis.icdName = icdName;
            diagnosis.isAdd = isAdd;
            return diagnosis;
        }

        private addDrug() {
            this.document.drugs.push(new NhifProtocolDrugDto());
        }

        private removeDrug(index: number) {
            arrayUtil.removeAt(this.document.drugs, index);
        }

        private resetDrugs() {
            this.document.drugs = [];
            this.document.drugs.push(new NhifProtocolDrugDto());
        }

        private async onDiagnosisChange(diagnosis: ExamDiagnosisDto | null) {
            this.resetDrugs();
            await this.getRequirements(diagnosis?.icdCode ?? '');
        }

        private setDrugInitialValue(index: number) {
            if (this.document?.drugs[index] === null) {
                this.document.drugs[index] = new NhifProtocolDrugDto();
                this.document.drugs[index].position = index + 1;
            }
        }

        private async getNhifOffices() {
            const response = await rhifService.getRhifs();
            if (response?.data) {
                this.nhifOffices = response.data;
            }
        }

        private async getCommunications() {
            const response = await nhifProtocolCommunicationService.getNhifProtocolCommunications();
            if (response?.data) {
                this.communications = response.data;
            }
        }

        private async getRequirements(icdCode: string) {
            const response = await nhifProtocolRequirementService.getNhifProtocolRequirements(icdCode);
            if (response?.data) {
                this.requirements = response.data;
            }
        }

        private async updateEventHandler() {
            this.$loading.show();
            try {
                let documentId = this.document.id;
                if (this.document.id === 0) {
                    const response = await drugProtocolService.createDrugProtocol(this.document);
                    documentId = Number(response.data);
                    this.copyObject();
                    eventBus.$emit('create-referral-event', documentId, 'NhifProtocol', 'Протокол за НЗОК лекарства');
                    this.$router.push(`/Exam/NhifProtocol/Edit/${documentId}`);
                } else {
                    await drugProtocolService.updateDrugProtocol(this.document);
                    eventBus.$emit('update-referral-event', 'Протокол за НЗОК лекарства');
                    this.copyObject();
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async deleteEventHandler() {
            this.$loading.show();
            try {
                const documentId = this.document.id;
                await drugProtocolService.deleteDrugProtocol(documentId);
                this.copyObject();
                this.$router.push(`/Exam/NhifProtocol/Create/${this.document.examId}`);
                eventBus.$emit('delete-referral-event', this.documentId, 'NhifProtocol', 'Протокол за НЗОК лекарства');
            } finally {
                this.$loading.hide();
            }
        }

        private printEventHandler() {
            if (this.documentId) {
                this.$router.push(`/Print/Exam.DrugProtocol.DrugProtocol/${this.documentId}`);
            }
        }

        private async load() {
            this.$loading.show();
            try {
                if (this.documentId) {
                    this.document = await drugProtocolService.getDrugProtocol(this.documentId, null);
                    this.getExamDiagnosisOnLoad();
                    this.onProtocolInfoChange();
                    await this.getRequirements(this.document.examDiagnosis?.icdCode ?? '');
                } else {
                    this.document = await drugProtocolService.getDrugProtocolInfoByExamId(this.examId);
                }
                await this.loadPreviousProtocols();
            } finally {
                this.$loading.hide();
            }
        }

        private async loadPreviousProtocols() {
            if (currentPatientCache?.value.key.patientId) {
                const response = await drugProtocolService.getPreviousProtocolsForPatient(
                    currentPatientCache.value.key
                );
                this.previousProtocols = response?.data ? response.data : [];
            }
        }

        private getExamDiagnosisOnLoad() {
            const examDiagnosis = currentVisitCache.value.exam?.examDiagnoses.find(
                (diagnosis) => diagnosis.id === this.document.examDiagnosis?.id
            );
            this.document.examDiagnosis = examDiagnosis ?? new ExamDiagnosisDto();
        }

        private async copyProtocol(id: number) {
            const previousProtocol = await drugProtocolService.getDrugProtocol(id, null);
            if (previousProtocol) {
                await this.populateDocument(previousProtocol);
            }
        }

        private async populateDocument(previousProtocol: NhifProtocolDto) {
            const examDiagnosis = currentVisitCache.value.exam?.examDiagnoses.find(
                (diagnosis) =>
                    diagnosis.icdCode === previousProtocol.examDiagnosis?.icdCode &&
                    diagnosis.icdCodeAdd === previousProtocol.examDiagnosis?.icdCodeAdd
            );
            this.document.examDiagnosis = examDiagnosis ?? new ExamDiagnosisDto();
            await this.getRequirements(this.document.examDiagnosis?.icdCode ?? '');
            this.document.validityPeriod = previousProtocol.validityPeriod;
            this.document.previousProtocol =
                previousProtocol.nhifProtocolInfo.nrn ?? previousProtocol.number.toString();
            this.document.decisionNumber = previousProtocol.decisionNumber;
            this.document.groupCode = previousProtocol.groupCode;
            this.document.nhifOfficeId = previousProtocol.nhifOfficeId;
            this.document.requirementId = previousProtocol.requirementId;
            this.document.communicationCode = previousProtocol.communicationCode;
            this.document.drugs = previousProtocol.drugs;
            // Минаваме през всички лекарстви и слагаме id да е 0, защото когато зареждаме данните
            // за стар протокол лекарствата му идват с id-та от базата, а така оставени при запис се получава exception
            for (const drug of this.document.drugs) {
                drug.id = 0;
            }
        }

        private async mounted() {
            await this.getNhifOffices();
            await this.getCommunications();
            this.getDiagnoses();
            await this.load();

            if (this.document.drugs.length < 1) {
                this.document.drugs.push(new NhifProtocolDrugDto());
            }

            this.copyObject();
        }

        private copyObject() {
            this.initialValue = this.$lodash.cloneDeep(this.document);
        }

        @Watch('document.drugs')
        private onDrugChange() {
            if (this.document?.drugs) {
                const drugs = this.document?.drugs;
                for (let index = 0; index < drugs.length; index++) {
                    if (drugs[index] === null) {
                        this.setDrugInitialValue(index);
                    } else {
                        drugs[index].position = index + 1;
                    }
                }
            }
        }
    }
</script>
