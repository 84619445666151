<template>
    <div>
        <v-card>
            <v-card-title>Партиди на ваксини</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="6">
                        <dropdown
                            v-model="selectedVaccineCode"
                            :items="vaccines"
                            item-value="code"
                            label="Ваксина:"
                            required
                            is-cyrillic-to-latin
                            @input="getVaccineLots"
                        />
                    </v-col>
                </v-row>
                <data-table
                    v-if="selectedVaccineCode && items"
                    title="Партиди"
                    :headers="tableHeaders"
                    :items="items"
                    sort-by="expirationDate"
                    sort-desc
                    show-search-box
                >
                    <template #actions>
                        <btn action="New" @click="beginCreateVaccineLot">Добавяне на партида</btn>
                    </template>
                    <template #rowActions="{ item }">
                        <btn icon action="Edit" @click="beginEditVaccineLot(item)" />
                    </template>
                </data-table>
            </v-card-text>
        </v-card>
        <v-row justify="center" class="mx-5">
            <v-dialog v-model="dialogModel.dialogIsVisible" persistent max-width="75%">
                <nhis-vaccine-lot-editor v-model="dialogModel" @saved="onSaved" @deleted="onDeleted" />
            </v-dialog>
        </v-row>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { NhisVaccineLotDialogModel } from '@/model/Nhis/VaccineLot/NhisVaccineLotDialogModel';
    import { NhisVaccineLotDto } from '@/model/Nhis/VaccineLot/NhisVaccineLotDto';
    import { NhisVaccineDto } from '@/model/Nomenclature/NhisVaccineDto';
    import { nhisVaccineLotService } from '@/service/Nhis/NhisVaccineLotService';
    import { nhisVaccineService } from '@/service/Nomenclature/NhisVaccineService';
    import { arrayUtil } from '@/util/ArrayUtil';
    import { formatters } from '@/util/Formatters';
    import NhisVaccineLotEditor from '@/view/Nhis/VaccineLot/NhisVaccineLotEditor.vue';

    @Component({
        components: { NhisVaccineLotEditor }
    })
    export default class NhisVaccineLotView extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        @Prop({ required: true })
        private doctorEmployeeSeqNumber!: number;

        private vaccines: NhisVaccineDto[] = [];
        private selectedVaccineCode: string = '';
        private items: NhisVaccineLotDto[] = [];
        private dialogModel: NhisVaccineLotDialogModel = new NhisVaccineLotDialogModel();

        private get tableHeaders(): IDataTableHeader[] {
            return [
                { text: 'Партида номер:', value: 'lotNumber' },
                {
                    text: 'Брой дози в опаковката:',
                    value: 'dosesInContainer'
                },
                {
                    text: 'Валидна до:',
                    value: 'expirationDate',
                    formatter: formatters.date
                }
            ];
        }

        private async mounted() {
            await this.getNhisVaccines();
        }

        private async getNhisVaccines() {
            const response = await nhisVaccineService.getNhisVaccines();
            if (response?.data) {
                this.vaccines = response.data;
            }
        }

        private async getVaccineLots(nhisVaccineCode: string) {
            const response = await nhisVaccineLotService.getNhisVaccineLots(
                this.practiceId,
                this.doctorEmployeeSeqNumber,
                nhisVaccineCode
            );
            if (response?.data) {
                this.items = response.data;
            }
        }

        private beginCreateVaccineLot() {
            const lot = new NhisVaccineLotDto();
            lot.practiceId = this.practiceId;
            lot.doctorEmployeeSeqNumber = this.doctorEmployeeSeqNumber;
            lot.nhisVaccineCode = this.selectedVaccineCode;
            this.dialogModel.lot = lot;
            this.dialogModel.lotExists = false;
            this.dialogModel.dialogIsVisible = true;
        }

        private async beginEditVaccineLot(lot: NhisVaccineLotDto) {
            Object.assign(this.dialogModel.lot, lot);
            this.dialogModel.lotExists = true;
            const response = await nhisVaccineLotService.isVaccineLotInUse(this.dialogModel.lot);
            this.dialogModel.lotIsInUse = response?.data ?? true;
            this.dialogModel.dialogIsVisible = true;
        }

        private onSaved() {
            if (this.dialogModel.lotExists) {
                const vaccineLot = this.items.find((lot) => lot.lotNumber === this.dialogModel.lot.lotNumber);
                if (vaccineLot) {
                    Object.assign(vaccineLot, this.dialogModel.lot);
                }
            } else {
                this.items.push(this.dialogModel.lot);
            }
        }

        private onDeleted() {
            arrayUtil.removeFirst(this.items, (item) => item.lotNumber === this.dialogModel.lot.lotNumber);
        }
    }
</script>
