import { plainToInstance } from 'class-transformer';

import { PatientDispensaryDataDto } from '@/model/Patient/Dispensary/PatientDispensaryDataDto';
import { DispensaryRuleDto } from '@/model/Register/Dispensary/DispensaryRuleDto';
import { PatientRegisterKey } from '@/model/Register/PatientRegisterKey';
import { httpService } from '@/service/Infrastructure/HttpService';

export const patientDispensaryVisitService = {
    async getPatientDispensaryRules(
        patientId: number,
        practiceId: number,
        doctorEmployeeSeqNumber: number
    ): Promise<DispensaryRuleDto[]> {
        const response = await httpService.get<DispensaryRuleDto[]>(
            `/PatientDispensaryVisit/GetPatientDispensaryRules`,
            {
                params: { patientId, practiceId, doctorEmployeeSeqNumber }
            }
        );

        return plainToInstance(DispensaryRuleDto, response.data);
    },
    async getDispensaryActivities(key: PatientRegisterKey): Promise<PatientDispensaryDataDto> {
        const response = await httpService.get<PatientDispensaryDataDto>(
            `/PatientDispensaryVisit/GetDispensaryActivities`,
            { params: key }
        );

        return plainToInstance(PatientDispensaryDataDto, response.data);
    },
    async saveDispensaryVisit(patientData: PatientDispensaryDataDto): Promise<number> {
        const response = await httpService.post<number>(`/PatientDispensaryVisit/SaveDispensaryVisit`, patientData);
        return response.data;
    }
};
