import { plainToInstance } from 'class-transformer';
import Vue from 'vue';

import { UserContextDto } from '@/model/User/UserContext/UserContextDto';
import { userContextService } from '@/service/User/UserContextService';
import { currentPatientCache } from '@/store/CurrentPatientCache';
import { currentVisitCache } from '@/store/CurrentVisitCache';
import { licensingCache } from '@/store/LicensingCache';
import { localServerCertificateCache } from '@/store/LocalServerCertificateCache';
import { accessTokenCache } from '@/store/NhisNhifAccessTokenCache';
import { currentUser } from '@/store/User/CurrentUser';

const userContextsKey: string = 'userContexts';
const currentUserContextKey: string = 'selectedUserContext';

class UserContextCache {
    private _isLoading: boolean = false;
    private _userContexts: UserContextDto[] = [];
    private _requestPracticeId: number | null = null;
    private _requestEmployeeSeqNumber: number | null = null;

    public get isLoading() {
        return this._isLoading;
    }

    public get userContexts() {
        return this._userContexts;
    }

    public findOrPrependUserContext(userContext: UserContextDto) {
        const exact = this.findExactUserContext(userContext);
        if (exact) {
            return exact;
        }

        // Ако в списъка няма точно такъв UserContext, той се добавя на първо място.
        this._userContexts.unshift(userContext);
        userContextService.fillIndices(this._userContexts);
        // Запис в localStorage.
        localStorage.setItem(userContextsKey, JSON.stringify(this._userContexts));
        return userContext;
    }

    public async loadFromServer() {
        this._isLoading = true;
        try {
            this._userContexts = await userContextService.getCurrentUserContexts();
            console.log(`Заредени ${this._userContexts.length} потребителски контекста от сървъра.`);
            // Запис в localStorage.
            localStorage.setItem(userContextsKey, JSON.stringify(this._userContexts));

            this.tryRestoreCurrentUserContext(true);
        } finally {
            this._isLoading = false;
        }
    }

    public loadFromLocalStorage() {
        this._isLoading = true;
        try {
            const json = localStorage.getItem(userContextsKey);
            if (json) {
                this._userContexts = plainToInstance(UserContextDto, JSON.parse(json) as UserContextDto[]);
                userContextService.fillIndices(this._userContexts);
            } else {
                this._userContexts = [];
            }
            console.log(`Заредени ${this._userContexts.length} потребителски контекста от local storage.`);

            this.tryRestoreCurrentUserContext(false);
        } finally {
            this._isLoading = false;
        }
    }

    private tryRestoreCurrentUserContext(isLoadingFromServer: boolean) {
        let suggested: UserContextDto | null = null;

        const json = localStorage.getItem(currentUserContextKey);
        if (json) {
            const storedUserContext = plainToInstance(UserContextDto, JSON.parse(json));

            // Последно избраният UserContext се активира само ако присъства в списъка.
            // Изключение: След презареждане от сървъра (след вход, при презареждане през grid-а с местоработи и т.н.)
            // UserContext-ите, които са били добавяни от глобален админ през търсенето, изчезват от списъка.
            // За удобство на глобалния админ, последно избраният UserContext се запазва, дори вече да не е актуален.
            if (isLoadingFromServer && currentUser.isGlobalAdmin) {
                suggested = this.findOrPrependUserContext(storedUserContext);
            } else {
                suggested = this.suggestUserContext(storedUserContext);
            }
        }

        // Ако в local storage няма записан контекст или той е невалиден, се избира първият контекст само ако е единствен.
        if (!suggested) {
            const usableContexts = this._userContexts.filter((uc) => !uc.currentUserIsPending);
            if (usableContexts.length === 1) {
                [suggested] = usableContexts;
            }
        }

        this.switchToRequestedOrSuggestedUserContext(suggested);

        this.beginUpdateLicensingCache();
    }

    private suggestUserContext(storedUserContext: UserContextDto): UserContextDto | null {
        // На теория, записаният в local storage UserContext може да не е сред списъка с актуалните.
        const exact = this.findExactUserContext(storedUserContext);
        if (exact) {
            return exact;
        }

        // Ако няма абсолютно същия контекст, се търси подобен за същия служител.
        console.log(
            'Зареден потребителски контекст от local storage, който не присъства в списъка:',
            storedUserContext
        );

        const similar = this.findSimilarUserContext(storedUserContext);
        if (similar) {
            console.log('Намерен подобен потребителски контекст за същия лекар.');
        }
        return similar;
    }

    private getPracticeContexts(practiceId: number) {
        return this._userContexts.filter((uc) => !uc.currentUserIsPending && uc.practiceId === practiceId);
    }

    private findExactUserContext(compareTo: UserContextDto) {
        const practiceContexts = this.getPracticeContexts(compareTo.practiceId);
        return (
            practiceContexts.find(
                (uc) =>
                    uc.employeeSeqNumber === compareTo.employeeSeqNumber &&
                    uc.doctorId === compareTo.doctorId &&
                    uc.specialtyCode === compareTo.specialtyCode &&
                    uc.nurseSeqNumber === compareTo.nurseSeqNumber &&
                    uc.deputyDoctorId === compareTo.deputyDoctorId &&
                    uc.deputyDoctorIsHired === compareTo.deputyDoctorIsHired &&
                    uc.isPracticeOwner === compareTo.isPracticeOwner
            ) ?? null
        );
    }

    private findSimilarUserContext(compareTo: UserContextDto) {
        const practiceContexts = this.getPracticeContexts(compareTo.practiceId);
        return (
            practiceContexts.find(
                (uc) =>
                    uc.doctorId === compareTo.doctorId &&
                    uc.specialtyCode === compareTo.specialtyCode &&
                    uc.nurseSeqNumber === compareTo.nurseSeqNumber &&
                    uc.deputyDoctorId === compareTo.deputyDoctorId &&
                    uc.deputyDoctorIsHired === compareTo.deputyDoctorIsHired
            ) ??
            practiceContexts.find(
                (uc) =>
                    uc.doctorId === compareTo.doctorId &&
                    uc.specialtyCode === compareTo.specialtyCode &&
                    uc.nurseSeqNumber === compareTo.nurseSeqNumber
            ) ??
            practiceContexts.find(
                (uc) => uc.doctorId === compareTo.doctorId && uc.specialtyCode === compareTo.specialtyCode
            ) ??
            practiceContexts.find((uc) => uc.doctorId === compareTo.doctorId) ??
            practiceContexts.find((uc) => uc.employeeSeqNumber === compareTo.employeeSeqNumber) ??
            null
        );
    }

    // Опит за отложено превключване към служител или само към практика, заявени изрично при директо зареждане на URI.
    private switchToRequestedOrSuggestedUserContext(suggested: UserContextDto | null) {
        if (this._requestPracticeId) {
            const practiceId = this._requestPracticeId;
            this._requestPracticeId = null;
            const practiceContexts = this.getPracticeContexts(practiceId);

            if (this._requestEmployeeSeqNumber) {
                // В URI-я е указан конкретен служител.
                const employeeSeqNumber = this._requestEmployeeSeqNumber;
                this._requestEmployeeSeqNumber = null;

                if (
                    suggested &&
                    practiceId === suggested.practiceId &&
                    employeeSeqNumber === suggested.employeeSeqNumber
                ) {
                    // Заявеният в URI-я служител е последно използваният. Превключва се към последно използвания контекст.
                    this._current = suggested;
                } else {
                    // В URI-я е заявен служител, различен от последно използвания. Превключва се към кой да е контекст за заявения служител.
                    this.trySwitchToUserContext(
                        practiceContexts.find((uc) => uc.employeeSeqNumber === employeeSeqNumber)
                    );
                }
                // В URI-я е указана само практика.
            } else if (suggested && practiceId === suggested.practiceId) {
                // Заявената в URI-я практика е последно използваната. Превключва се към последно използвания контекст.
                this._current = suggested;
            } else {
                // В URI-я е заявена практика, различна от последно използваната. Превключва се към най-близкия контекст за заявената практика.
                this.trySwitchToUserContext(
                    (suggested ? practiceContexts.find((uc) => uc.doctorId === suggested.doctorId) : null) ??
                        practiceContexts.find(() => true)
                );
            }
        } else {
            // В URI-я не е указана практика. Превключва се към последно използвания контекст, ако има такъв.
            this._current = suggested;
        }
    }

    private _current: UserContextDto | null = null;

    public get current() {
        return this._current;
    }

    // Извиква се при click в grid-а с местоработи, както и от trySwitchTo... методите.
    // Не се извиква при начално зареждане на страницата, освен ако съхранените UserContext-и не са остарели.
    public set current(userContext: UserContextDto | null) {
        if (this._current?.practiceId !== userContext?.practiceId) {
            currentPatientCache.clear();
            currentVisitCache.clear();
        }
        accessTokenCache.clear();
        localServerCertificateCache.clear();

        this._current = userContext;

        // Запис в localStorage.
        if (userContext) {
            localStorage.setItem(currentUserContextKey, JSON.stringify(userContext));
        } else {
            localStorage.removeItem(currentUserContextKey);
        }

        this.beginUpdateLicensingCache();
    }

    private beginUpdateLicensingCache() {
        const current = this._current;
        if (current) {
            if (current.practiceId !== licensingCache.status.practiceFeatures.practiceId) {
                // Без await, за да се изпълнява асинхронно.
                licensingCache.load(current.practiceId);
            }
        } else {
            licensingCache.clear();
        }
    }

    // Shortcut методи до по-често използваните property-та на текущия контекст.

    public get currentDoctorEmployeeSeqNumber() {
        return this._current?.doctorId ? this._current.employeeSeqNumber : null;
    }

    public get currentDoctorEmployeeId() {
        return this._current?.doctorEmployeeId ?? null;
    }

    public get currentPracticeId() {
        return this._current?.practiceId ?? null;
    }

    public get currentIsGlobalAdminOrPracticeOwner() {
        return currentUser.isGlobalAdmin || Boolean(this._current?.isPracticeOwner);
    }

    // Превключва към най-близкия UserContext за подаден друг служител.
    public trySwitchToEmployee(practiceId: number, employeeSeqNumber: number): boolean {
        const current = this._current;
        if (!current) {
            // Контекстите още не са заредени. След като се заредят, ще бъде избран контекст за желания служител.
            this._requestPracticeId = practiceId;
            this._requestEmployeeSeqNumber = employeeSeqNumber;
            return false;
        }
        if (practiceId === current.practiceId && employeeSeqNumber === current.employeeSeqNumber) {
            return true;
        }
        const practiceContexts = this.getPracticeContexts(practiceId);
        return this.trySwitchToUserContext(practiceContexts.find((uc) => uc.employeeSeqNumber === employeeSeqNumber));
    }

    // Превключва към най-близкия UserContext за подадена друга практика.
    public trySwitchToPractice(practiceId: number): boolean {
        const current = this._current;
        if (!current) {
            // Контекстите още не са заредени. След като се заредят, ще бъде избран контекст за желаната практика.
            this._requestPracticeId = practiceId;
            this._requestEmployeeSeqNumber = null;
            return false;
        }
        if (practiceId === current.practiceId) {
            return true;
        }
        const practiceContexts = this.getPracticeContexts(practiceId);
        return this.trySwitchToUserContext(
            practiceContexts.find((uc) => uc.doctorId === current.doctorId) ?? practiceContexts.find(() => true)
        );
    }

    // Превключва към най-близкия UserContext за подаден друг лекар.
    public trySwitchToDoctor(doctorId: number): boolean {
        const current = this._current;
        if (!current) {
            // TODO:
            // Контекстите още не са заредени. След като се заредят, ще бъде избран контекст за желания лекар.
            //this._requestDoctorId = doctorId;
            return false;
        }
        if (doctorId === current.doctorId) {
            return true;
        }
        const practiceContexts = this.getPracticeContexts(current.practiceId);
        return this.trySwitchToUserContext(
            practiceContexts.find((uc) => uc.doctorId === doctorId) ??
                this._userContexts.find((uc) => uc.doctorId === doctorId)
        );
    }

    // Ако не съществува UserContext за подадената практика или лекар, текущият UserContext не се нулира.
    // Не е сигурно дали това е правилното поведение. Засега идеята е, че ако се зарежда страница,
    // за която текущият потребител няма права, трябва да се изведе грешка без да се губи контекстът.
    private trySwitchToUserContext(userContext: UserContextDto | undefined): boolean {
        if (userContext) {
            this.current = userContext;
            return true;
        }
        return false;
    }
}

export const userContextCache = Vue.observable(new UserContextCache());
