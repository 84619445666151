export class PatientSpecialActivityDto {
    public ruleId: number = 0;
    public activityType: string = '';
    public totalActivities: number = 0;
    public totalPerformedActivities: number = 0;
    public lastPerformedActivityDate: string = '';
    public activityName: string = '';
    public labTestName: string = '';
    public isPerformed: boolean = false;
    public activityDescription: string = '';
    public immunizationId: number | null = null;
    public isSelectable: boolean = false;

    // Използва се само на клиента
    public get code() {
        return `${this.activityName}-${this.immunizationId ?? this.ruleId}-${this.activityDescription}`;
    }
}
