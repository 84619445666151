<template>
    <div>
        <v-card-title class="mb-3 pt-0">Данни за лекар</v-card-title>
        <v-card-text>
            <v-row>
                <v-col :cols="row ? 3 : 12" class="pa-1">
                    <text-field v-model="practiceNumber" disabled label="РЦЗ Номер" />
                </v-col>
                <v-col :cols="row ? 3 : 12" class="pa-1">
                    <text-field v-model="doctorUin" disabled label="УИН" />
                </v-col>
                <v-col :cols="row ? 3 : 12" class="pa-1">
                    <text-field
                        v-model="deputyDoctorUin"
                        disabled
                        label="УИН на заместващия/нает лекар/медицинска сестра/акушерка/лекарски асистенти"
                    />
                </v-col>
                <v-col :cols="row ? 3 : 12" class="pa-1">
                    <text-field v-model="doctorSpecialtyName" disabled label="Код специалист" />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="8" class="pa-1">
                    <text-field v-model="doctorName" disabled label="Лекар имена" />
                </v-col>
                <v-col cols="6" md="2" class="pa-1">
                    <v-checkbox :input-value="isDeputyDoctor && deputyDoctorIsHired" dense disabled label="Нает" />
                </v-col>
                <v-col cols="6" md="2" class="pa-1">
                    <v-checkbox
                        :input-value="isDeputyDoctor && !deputyDoctorIsHired"
                        disabled
                        dense
                        :label="row ? 'Заместващ' : 'Зам.'"
                    />
                </v-col>
            </v-row>
        </v-card-text>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import { userContextCache } from '@/store/User/UserContextCache';

    @Component
    export default class DoctorInfo extends Vue {
        // Иван: В някои Vuetify компоненти (v-layout, v-radio-group) използват row, за да предизвикат
        // хоризонтално разположение(да съберат нещо на един ред), затова и тук избрах това име на prop.
        @Prop()
        private row!: boolean;

        private get practiceNumber() {
            return userContextCache.current?.practiceNumber;
        }

        private get doctorUin() {
            return currentVisitCache.isLoaded ? currentVisitCache.value.doctorUin : userContextCache.current?.doctorUin;
        }

        private get doctorSpecialtyName() {
            return currentVisitCache.isLoaded
                ? currentVisitCache.value.doctorSpecialtyName
                : userContextCache.current?.specialtyName;
        }

        private get deputyDoctorUin() {
            return currentVisitCache.isLoaded
                ? currentVisitCache.value.exam?.deputyDoctorUin
                : userContextCache.current?.deputyDoctorUin;
        }

        private get isDeputyDoctor() {
            return Boolean(
                currentVisitCache.isLoaded
                    ? currentVisitCache.value.exam?.deputyDoctorUin
                    : userContextCache.current?.deputyDoctorId
            );
        }

        private get deputyDoctorIsHired() {
            return currentVisitCache.isLoaded
                ? currentVisitCache.value.exam?.deputyDoctorIsHired
                : userContextCache.current?.deputyDoctorIsHired;
        }

        private get doctorName() {
            if (this.isDeputyDoctor) {
                return this.deputyDoctorName;
            }

            return currentVisitCache.isLoaded
                ? currentVisitCache.value.doctorNames
                : userContextCache.current?.doctorName;
        }

        private get deputyDoctorName() {
            return currentVisitCache.isLoaded
                ? currentVisitCache.value.exam?.deputyDoctorName
                : userContextCache.current?.deputyDoctorName;
        }
    }
</script>
