import { AxiosResponse } from 'axios';

import { InsuranceCheckRequestDto } from '@/model/Patient/InsuranceStatus/InsuranceCheckRequestDto';
import { InsuranceStatusDto } from '@/model/Patient/InsuranceStatus/InsuranceStatusDto';
import { PatientInsuranceCheckRequestDto } from '@/model/Patient/InsuranceStatus/PatientInsuranceCheckRequestDto';
import { httpService } from '@/service/Infrastructure/HttpService';
import { notifierService } from '@/service/Infrastructure/NotifierService';
import { userContextCache } from '@/store/User/UserContextCache';
import { dateUtil } from '@/util/DateUtil';

export const insuranceStatusService = {
    async checkStatus(
        patientId: number,
        pidTypeCode: string,
        identifier: string,
        // TODO: Да се махне. При проверка на осиг. статус не би следвало да се подава осиг. статус.
        insuranceStatus: InsuranceStatusDto
    ): Promise<InsuranceStatusDto | null> {
        const patientData = new PatientInsuranceCheckRequestDto(patientId, pidTypeCode, identifier, insuranceStatus);

        const request = new InsuranceCheckRequestDto();
        request.practiceId = userContextCache?.currentPracticeId ?? 0;
        request.patient = patientData;

        const response = await this.insuranceStatusCheck(request);
        if (response && response.data) {
            if (response.data.isInsured) {
                await notifierService.showSuccess(
                    '',
                    `Към дата: ${dateUtil.formatBgDate(new Date())} пациентът е със статус "Осигурен"`
                );
            } else {
                await notifierService.showWarning(
                    '',
                    `Към дата: ${dateUtil.formatBgDate(new Date())} пациентът е със статус "Неосигурен"`
                );
            }
            if (response.data.exceptionMessage) {
                await notifierService.showError('Внимание', response.data.exceptionMessage);
            }
            return response.data;
        }

        return null;
    },

    insuranceStatusCheck(data: InsuranceCheckRequestDto): Promise<AxiosResponse<InsuranceStatusDto>> {
        return httpService.post('/InsuranceStatus/InsuranceStatusCheck/', data);
    },

    async checkGroupInsuranceStatus(data: InsuranceCheckRequestDto): Promise<void> {
        await httpService.post('/InsuranceStatus/InsuranceStatusGroupCheck', data);
    },

    async getPatientsCount(data: InsuranceCheckRequestDto): Promise<number> {
        const response = await httpService.post<number>('/InsuranceStatus/GetPatientsCount', data);
        return response?.data;
    }
};
