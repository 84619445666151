<template>
    <!-- Ref-ът не може да се сложи на самия v-data-table, защото той няма метод getBoundingClientRect (виж най-долу). -->
    <div v-if="items.length > 0" ref="tableContainer">
        <v-data-table
            v-resize="calcDataTableHeight"
            v-scroll="calcDataTableHeight"
            :headers="headers"
            :items="items"
            :item-class="itemRowBackground"
            fixed-header
            :height="tableHeight"
            disable-pagination
            hide-default-footer
        >
            <template #[`item.patientIdentifier`]="{ item }">
                <router-link class="contrast--text" :to="`/Patient/VisitSummary/${item.patientVersionKey.patientId}`">
                    {{ item.patientIdentifier }}
                </router-link>
            </template>

            <template #[`item.startDateTime`]="{ item }">
                <date-label v-model="item.startDateTime" show-time />
            </template>

            <template #[`item.id`]="{ item }">
                <td class="rowActions">
                    <btn icon action="Edit" @click="editVisit(item)" />
                    <btn
                        v-if="item.ambulatorySheetNumber !== null"
                        action="Copy"
                        icon
                        @click="copyVisit(item.id, item.patientVersionKey.patientId)"
                    >
                        Копиране на преглед
                    </btn>
                </td>
            </template>
        </v-data-table>

        <v-card-text>
            <v-row>
                <v-col>Брой: {{ items.length }}</v-col>
                <v-spacer></v-spacer>
                <v-col>Легенда:</v-col>
                <v-col><label class="px-5 py-1 style-consultation">Консултация</label></v-col>
                <v-col><label class="px-5 py-1 style-prevention">Профилактичен</label></v-col>
                <v-col cols="auto"><label class="px-5 py-1 style-isrisk">Профилактичен-Риск</label></v-col>
                <v-col><label class="px-5 py-1 style-dispensary">Диспансерен</label></v-col>
                <v-col><label class="px-5 py-1 style-canceled">Анулиран</label></v-col>
                <v-col cols="auto"><label class="px-5 py-1 style-no-nhif">Не се заплаща от НЗОК</label></v-col>
            </v-row>
        </v-card-text>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

    import DateLabel from '@/component/Date/DateLabel.vue';
    import { NhifVisitReasonCode } from '@/enum/Exam/NhifVisitReasonCode';
    import { NhisExamStatusCode } from '@/enum/Nhis/NhisExamStatusCode';
    import { FundCode } from '@/enum/Nomenclature/FundCode';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { VisitResultDto } from '@/model/VisitList/VisitSearch/VisitResultDto';
    import { currentPatientCache } from '@/store/CurrentPatientCache';

    @Component({
        components: { DateLabel }
    })
    export default class VisitDataTable extends Vue {
        @Prop({ required: true })
        private items!: VisitResultDto[];

        @Prop({ required: true })
        private showPatientData!: boolean;

        // Указва колко място да се запази под таблицата, така че да се съберат footer-ът и padding-ите
        // на parent компонентите. Целта е страницата да няма скролер, за да не стават два скролера.
        @Prop({ required: true })
        private bottomAnchor!: number;

        private headers: IDataTableHeader[] = [];

        private itemRowBackground(item: VisitResultDto) {
            if (item.statusCode === NhisExamStatusCode.Canceled) {
                return 'style-canceled';
            }
            if (item.fundCode !== FundCode.Nhif) {
                return 'style-no-nhif';
            }
            if (item.nhifVisitReasonCode) {
                if (
                    item.nhifVisitReasonCode === NhifVisitReasonCode.AdultPrevention ||
                    item.nhifVisitReasonCode === NhifVisitReasonCode.ChildPrevention ||
                    item.nhifVisitReasonCode === NhifVisitReasonCode.MotherPrevention
                ) {
                    return 'style-prevention';
                }
                if (item.nhifVisitReasonCode === NhifVisitReasonCode.AdultRiskPrevention) {
                    return 'style-isrisk';
                }
                if (item.nhifVisitReasonCode === NhifVisitReasonCode.Consultation) {
                    return 'style-consultation darkMode';
                }
                if (item.nhifVisitReasonCode === NhifVisitReasonCode.Dispensary) {
                    return 'style-dispensary';
                }
            }
            return 'style-main';
        }

        private copyVisit(id: number, patientId: number) {
            currentPatientCache.loadLastVersion(patientId);
            this.$router.push(`/Exam/AmbulatorySheet/Copy/${id}`);
        }

        private editVisit(visit: VisitResultDto) {
            currentPatientCache.loadSpecificVersion(visit.patientVersionKey);
            this.$loading.show();
            // В краен случай, при празно/счупено посещение, опитва да зареди АЛ.
            const path =
                visit.ambulatorySheetNumber !== null
                    ? `/Exam/AmbulatorySheet/Edit/${visit.id}`
                    : visit.sickSheetCount
                    ? `/Exam/SickSheet/${visit.id}`
                    : visit.hasStagedEpicrisis
                    ? `/Exam/StagedEpicrisis/${visit.id}`
                    : visit.hasFiles
                    ? `/Exam/FileUpload/${visit.id}`
                    : visit.hasExternalVisit
                    ? `/Exam/Results/${visit.id}`
                    : `/Exam/AmbulatorySheet/Edit/${visit.id}`;
            this.$router.push(path);
        }

        private mounted() {
            if (this.showPatientData) {
                this.headers = [
                    { text: 'ЕГН / ЛНЧ', value: 'patientIdentifier' },
                    { text: 'Пациент', value: 'patientFullName' },
                    { text: 'АЛ', value: 'examNumberOrVisitType' },
                    { text: 'НРН', value: 'nrn' },
                    { text: 'Дата и час', value: 'startDateTime' },
                    { text: 'Извършен от', value: 'createdBy' },
                    { text: 'МКБ', value: 'icdCode' },
                    { text: 'Рецепти', value: 'prescription' },
                    { text: 'Направление', value: 'referral3' },
                    { text: 'Напр. ВСД', value: 'referral3a' },
                    { text: 'МДН', value: 'referral4' },
                    { text: 'Изсл.', value: 'labTestOrders' },
                    { text: 'Брой БЛ', value: 'sickSheetCountOrNull' },
                    { text: 'Хоспитализация', value: 'referral7' },
                    { text: 'Имунизации', value: 'immunization' },
                    { text: '', value: 'id' }
                ];
            } else {
                this.headers = [
                    { text: 'АЛ', value: 'examNumberOrVisitType' },
                    { text: 'НРН', value: 'nrn' },
                    { text: 'Дата и час', value: 'startDateTime' },
                    { text: 'Извършен от', value: 'createdBy' },
                    { text: 'МКБ', value: 'icdCode' },
                    { text: 'Рецепти', value: 'prescription' },
                    { text: 'Направление', value: 'referral3' },
                    { text: 'Напр. ВСД', value: 'referral3a' },
                    { text: 'МДН', value: 'referral4' },
                    { text: 'Изсл.', value: 'labTestOrders' },
                    { text: 'Брой БЛ', value: 'sickSheetCountOrNull' },
                    { text: 'Хоспитализация', value: 'referral7' },
                    { text: 'Имунизации', value: 'immunization' },
                    { text: '', value: 'id' }
                ];
            }
        }

        @Ref()
        private readonly tableContainer!: Element;

        private tableHeight: number = 0;

        public calcDataTableHeight() {
            const legendHeight = 56;
            this.tableHeight =
                window.innerHeight - this.tableContainer.getBoundingClientRect().y - legendHeight - this.bottomAnchor;
        }
    }
</script>

<style scoped>
    /* Ако няма !important, редовете си губят цвета при hover, което в цветна таблица пречи. */
    ::v-deep .style-prevention {
        background-color: var(--v-accent2-base) !important;
    }

    ::v-deep .style-consultation {
        background-color: var(--v-success2-base) !important;
    }

    ::v-deep .style-isrisk {
        background-color: var(--v-info2-base) !important;
    }

    ::v-deep .style-dispensary {
        background-color: var(--v-warning2-base) !important;
    }

    ::v-deep .style-no-nhif {
        background-color: var(--v-secondary2-base) !important;
    }

    ::v-deep .style-canceled {
        background-color: var(--v-error2-base) !important;
    }

    ::v-deep .style-main {
        background-color: transparent;
    }

    .rowActions {
        position: sticky;
        right: 0;
    }
</style>
