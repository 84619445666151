import { plainToInstance } from 'class-transformer';
import Vue from 'vue';

import { PatientVersionDto } from '@/model/Patient/PatientVersionDto';
import { PatientVersionKey } from '@/model/Patient/PatientVersionKey';
import { patientService } from '@/service/Patient/PatientService';

const currentPatientKey: string = 'currentPatient';

const emptyValue = new PatientVersionDto();

class CurrentPatientCache {
    // Когато кешът не е зареден, връща празен обект вместо null, за да не гърми bind-ването на компонентите.
    private _value: PatientVersionDto = emptyValue;

    public get value() {
        return this._value;
    }

    public get isLoaded() {
        return this._value !== emptyValue;
    }

    public clear() {
        localStorage.removeItem(currentPatientKey);
        this._value = emptyValue;
    }

    public get haveActiveFeeDisengagement() {
        if (this._value && this._value.feeDisengagements.length > 0) {
            for (const { endDate, isLifeTime, startDate } of this._value.feeDisengagements) {
                if (isLifeTime) {
                    return true;
                }

                const today = new Date();

                if (startDate && endDate && startDate <= today && endDate >= today) {
                    return true;
                }
            }

            return false;
        }

        return false;
    }

    public get isFeeFree() {
        const adultAge = 18;
        return this.haveActiveFeeDisengagement || (this._value.age && this._value.age < adultAge);
    }

    public async loadSpecificVersion(key: PatientVersionKey) {
        const oldKey = this._value.key;
        if (
            key.practiceId !== oldKey.practiceId ||
            key.patientId !== oldKey.patientId ||
            key.seqNumber !== oldKey.seqNumber
        ) {
            const patientVersion = await patientService.getSpecificPatientVersion(key);
            if (patientVersion) {
                this._value = patientVersion;
                this.saveToLocalStorage();
            } else {
                this.clear();
            }
        }
    }

    // TODO: Да се махне default стойността и gpEmployeeSeqNumber да подава навсякъде.
    public async loadLastVersion(patientId: number, gpEmployeeSeqNumber: number | null = null) {
        const current = this._value;

        if (
            patientId !== current.key.patientId ||
            gpEmployeeSeqNumber !== current.gpEmployeeSeqNumber ||
            (!gpEmployeeSeqNumber && current.key.seqNumber !== current.lastVersionSeqNumber)
        ) {
            const patientVersion = await patientService.getLastPatientVersion(patientId, gpEmployeeSeqNumber);
            if (patientVersion) {
                this._value = patientVersion;
                this.saveToLocalStorage();
            } else {
                this.clear();
            }
        }
    }

    public loadFromLocalStorage() {
        const json = localStorage.getItem(currentPatientKey);
        if (json) {
            const patientVersion = plainToInstance(PatientVersionDto, JSON.parse(json));
            // Възможно е в local storage да попадне счупен json, например '""', затова се проверява дали plainToInstance е създало обект.
            if (patientVersion instanceof PatientVersionDto) {
                patientVersion.idCardIssueDate = patientVersion.idCardIssueDate
                    ? new Date(patientVersion.idCardIssueDate)
                    : null;
                patientVersion.pensionStatusCheckDate = patientVersion.pensionStatusCheckDate
                    ? new Date(patientVersion.pensionStatusCheckDate)
                    : null;
                patientVersion.registrationDate = new Date(patientVersion.registrationDate);
                patientVersion.deregistrationDate = patientVersion.deregistrationDate
                    ? new Date(patientVersion.deregistrationDate)
                    : null;
                this._value = patientVersion;
            }
        }
    }

    private saveToLocalStorage() {
        localStorage.setItem(currentPatientKey, JSON.stringify(this._value));
    }

    public changeAgeFromBirthDate(birthDate: Date | null) {
        if (this.isLoaded && birthDate) {
            const epochYear = 1970;
            this._value.age = birthDate
                ? new Date(Date.now() - new Date(birthDate).valueOf()).getFullYear() - epochYear
                : 0;
            this._value.personIdentification.birthDate = birthDate;
            this.saveToLocalStorage();
        }
    }
}

export const currentPatientCache = Vue.observable(new CurrentPatientCache());
