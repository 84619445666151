import { Type } from 'class-transformer';

import { SpecialtyCode } from '@/enum/Nomenclature/SpecialtyCode';
import { EmployeeUserDto } from '@/model/Employee/EmployeeUserDto';

export class UserContextDto {
    // Изкуствено добавен пореден номер на обекта в масива userContexts, защото няма уникално id.
    // Използва се само като item-key в data-table-а за избор на месторабота.
    public index: number = 0;

    // Различни видове id-та от базата данни. Нито едно не е уникално в масива userContexts.
    // Ключ.
    public practiceId: number = 0;
    public employeeSeqNumber: number = 0;
    public deputyDoctorId: number | null = null;
    public deputyDoctorIsHired: boolean | null = null;
    public nurseSeqNumber: number | null = null;
    public isPracticeOwner: number = 0;
    // Още колони от UserContext, които се определят еднозначно от ключа.
    public doctorId: number | null = null;
    public specialtyCode: SpecialtyCode | null = null;
    // ...като тази ще отпадне.
    public doctorEmployeeId: number | null = null;
    public currentUserIsPending: boolean = false;

    @Type(() => EmployeeUserDto)
    public users: EmployeeUserDto[] = [];

    // Lookup стойности.
    public practiceNumber: string = '';
    public practiceName: string = '';
    public hasReception: boolean = false;
    public practiceCategoryCode: string = '';
    public employeeDescription: string = '';
    // При повечето обръщания към ПИС се подава ЕГН на лекаря.
    public doctorIdentifier: string | null = null;
    public doctorUin: string | null = null;
    public doctorName: string | null = null;
    public nurseName: string | null = null;
    public deputyDoctorUin: string | null = null;
    public deputyDoctorName: string | null = null;
    // При някои обръщания към ПИС се подава НЗОК код на специалност.
    public specialtyNhifCode: string | null = null;
    public specialtyName: string | null = null;

    // Показва дали този UserContext се отнася директно за служителя или произтича от заместващ/нает или мед. сестра.
    public get isPrimary() {
        return !this.deputyDoctorId && !this.nurseSeqNumber;
    }

    public get gridPractice() {
        return `${this.currentUserIsPending ? 'Вие очаквате одобрение за ' : ''}${this.practiceNumber}${
            this.practiceName ? `, ${this.practiceName}` : ''
        }`;
    }

    public get gridEmployee() {
        const doctorOrNurseText = this.nurseSeqNumber
            ? `мед. сестра ${this.nurseName} на ${this.doctorName}`
            : this.doctorName;
        return this.doctorId
            ? `${this.doctorUin}${doctorOrNurseText ? `, ${doctorOrNurseText}` : ''}`
            : this.employeeDescription;
    }

    public get gridSpecialty() {
        return this.specialtyNhifCode && this.specialtyNhifCode !== '00'
            ? `${this.specialtyNhifCode}, ${this.specialtyName}`
            : this.specialtyName;
    }

    public get gridDeputy() {
        return this.deputyDoctorId
            ? `${this.deputyDoctorUin}, ${this.deputyDoctorIsHired ? 'нает' : 'зам.'} ${this.deputyDoctorName}`
            : '';
    }

    public get gridUsers() {
        return this.users ? this.users.map((user) => user.personAndUserName).join(', ') : '';
    }
}
