import { plainToInstance } from 'class-transformer';

import { EmployeeUserCreateCommand } from '@/model/Employee/EmployeeUserCreateCommand';
import { EmployeeUserDto } from '@/model/Employee/EmployeeUserDto';
import { EmployeeUserExistanceDto } from '@/model/Employee/Invitation/EmployeeUserExistanceDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const employeeUserService = {
    async checkEmployeeUserExistance(
        practiceId: number,
        employeeSeqNumber: number,
        email: string
    ): Promise<EmployeeUserExistanceDto> {
        const response = await httpService.get<EmployeeUserExistanceDto>('EmployeeUser/CheckEmployeeUserExistance', {
            params: { practiceId, employeeSeqNumber, email }
        });
        return plainToInstance(EmployeeUserExistanceDto, response.data);
    },

    async getEmployeeUsersByPractice(practiceId: number): Promise<EmployeeUserDto[]> {
        const response = await httpService.get<EmployeeUserDto[]>('EmployeeUser/GetEmployeeUsersByPractice', {
            params: { practiceId }
        });
        return plainToInstance(EmployeeUserDto, response.data);
    },

    async createOrInviteEmployeeUser(
        practiceId: number,
        employeeSeqNumber: number,
        emailOrUserName: string
    ): Promise<void> {
        const command = new EmployeeUserCreateCommand(practiceId, employeeSeqNumber, emailOrUserName);
        await httpService.post('EmployeeUser/CreateOrInviteEmployeeUser', command);
    },

    async approveEmployeeUser(practiceId: number, employeeSeqNumber: number, userId: string): Promise<void> {
        await httpService.put('EmployeeUser/ApproveEmployeeUser', null, {
            params: { practiceId, employeeSeqNumber, userId }
        });
    },

    async deleteEmployeeUser(practiceId: number, employeeSeqNumber: number, userId: string): Promise<void> {
        await httpService.delete('EmployeeUser/DeleteEmployeeUser', {
            params: { practiceId, employeeSeqNumber, userId }
        });
    },

    async deleteEmployeeUserInvitation(practiceId: number, employeeSeqNumber: number, email: string): Promise<void> {
        await httpService.delete('EmployeeUser/DeleteEmployeeUserInvitation', {
            params: { practiceId, employeeSeqNumber, email }
        });
    }
};
