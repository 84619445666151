<template>
    <v-form ref="form" v-model="isFormValid">
        <v-card>
            <v-card-title> Групова проверка на пенсионен статус </v-card-title>
            <v-card-text>
                <v-row class="flex-row align-start">
                    <!-- * За момента скриваме тази проверка, защото се изпълнява бавно и в Azure не завършва успешно-->
                    <v-col v-if="isGp" cols="12" md="3">
                        <label>Всички пациенти от пациентската листа:</label>
                        <v-switch v-model="value.allPatients" :label="value.allPatients ? 'Да' : 'Не'"></v-switch>
                    </v-col>
                    <v-col v-if="!value.allPatients" cols="9">
                        <label>Прегледани пациента за период:</label>
                        <v-row class="pt-2">
                            <v-col cols="3">
                                <date-picker v-model="value.startDate" label="Период от" required />
                            </v-col>
                            <v-col cols="3">
                                <date-picker v-model="value.endDate" label="Период до" required />
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row>
                    <v-textarea v-model="resultText" label="Резултат от изпращането" />
                </v-row>
            </v-card-text>
            <v-card-actions>
                <btn action="CheckDocument" @click="checkStatus" />
            </v-card-actions>
        </v-card>
    </v-form>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { SpecialtyCode } from '@/enum/Nomenclature/SpecialtyCode';
    import { PensionStatusGroupCheckDto } from '@/model/Integration/Nssi/PensionStatusGroupCheckDto';
    import { nssiService } from '@/service/Integration/NssiService';
    import { userContextCache } from '@/store/User/UserContextCache';

    @Component
    export default class PensionStatusGroupCheck extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        @Prop({ required: true })
        private doctorEmployeeSeqNumber!: number;

        private isFormValid: boolean = false;
        private value: PensionStatusGroupCheckDto = new PensionStatusGroupCheckDto();
        private resultText: string = '';

        private get isGp() {
            return (
                userContextCache.userContexts.find(
                    (uc) => uc.practiceId === this.practiceId && uc.employeeSeqNumber === this.doctorEmployeeSeqNumber
                )?.specialtyCode === SpecialtyCode.Gp
            );
        }

        private async checkStatus() {
            this.$loading.show();
            try {
                (this.$refs.form as Vue & { validate: () => boolean }).validate();
                if (this.isFormValid) {
                    this.value.practiceId = this.practiceId;
                    this.value.doctorEmployeeSeqNumber = this.doctorEmployeeSeqNumber;
                    await this.checkStatusByPortions();
                    this.$notifier.showSuccess('', 'Проверката завърши успешно!');
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async checkStatusByPortions() {
            //Заради бавната проверка на голяма порция от пациенти разделяме проверката на порции по 500
            const portionSize = 500;
            const patientsCount = await nssiService.getPensionStatusCountToCheck(this.value);
            this.resultText = `Общият брой на пациентите подлежащи на проверка е ${patientsCount}. Проверката стартира:`;
            //Ако броят на пациентите е под 500 ги пускаме накуп
            if (patientsCount <= portionSize) {
                await nssiService.pensionStatusGroupCheck(this.value);
            } else {
                const iterations = Math.ceil(patientsCount / portionSize);
                /* eslint-disable no-await-in-loop */
                for (let currentIteration = 0; currentIteration < iterations; currentIteration++) {
                    this.value.skipCount = currentIteration * portionSize;
                    const patientsToCheck = (currentIteration + 1) * portionSize;
                    const patientsLeftToCheck = patientsCount - patientsToCheck;
                    //Ако броят на пациентите оставащи да бъдат проверени е по-голям от portionSize, то взимаме толкова пациенти,
                    //колкото е portionSize, ако не, взимаме колкото пациенти са останали;
                    this.value.takeCount = patientsLeftToCheck > portionSize ? portionSize : patientsLeftToCheck;
                    await nssiService.pensionStatusGroupCheck(this.value);
                    this.resultText += `\r\n Проверени: ${
                        patientsToCheck <= patientsCount ? patientsToCheck : patientsCount
                    }`;
                }
                /* eslint-enable no-await-in-loop */
            }
        }
    }
</script>
